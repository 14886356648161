import { colors } from '@/shared/tokens/colors.stylex';
import React from 'react';

interface AddToCompareIconProps {
  checked: boolean;
}

export const AddToCompareIcon: React.FC<AddToCompareIconProps> = ({ checked }) => {
  return (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="28"
        height="28"
        rx="14"
        fill={checked ? `${colors.accentBackground}` : `${colors.brandGhostBackground}`}
        style={{ transition: 'fill 0.3s ease' }}
      />
      {checked && (
        <rect
          width="28"
          height="28"
          rx="14"
          fill={colors.primaryText}
          fillOpacity="0.05"
          style={{ transition: 'fill 0.3s ease, fill-opacity 0.3s ease' }}
        />
      )}
      <path
        d="M11.0379 14.9129C11.0129 15.1051 11 15.301 11 15.5C11 15.7001 11.0131 15.8971 11.0384 16.0902M14.9136 11.0379C15.1055 11.0129 15.3013 11 15.5 11C15.6987 11 15.8945 11.0129 16.0864 11.0379M13.7764 11.3419C13.4104 11.4938 13.0687 11.6926 12.759 11.9308M11.9277 12.763C11.6904 13.0724 11.4923 13.4135 11.3409 13.7788M16.9621 13.0871C16.9871 12.8949 17 12.699 17 12.5C17 12.2999 16.9869 12.1029 16.9616 11.9098M13.0864 16.9621C12.8945 16.9871 12.6987 17 12.5 17C12.3013 17 12.1055 16.9871 11.9136 16.9621M15.241 16.0692C14.9313 16.3074 14.5896 16.5062 14.2236 16.6581M16.6591 14.2212C16.5077 14.5865 16.3096 14.9276 16.0723 15.237M20 15.5C20 17.9853 17.9853 20 15.5 20C13.6248 20 12.0176 18.8531 11.3414 17.2224C11.2855 17.0877 11.236 16.9496 11.1932 16.8086C11.0512 16.7656 10.9124 16.7145 10.7767 16.6582C9.14651 15.9818 8 14.3748 8 12.5C8 10.0147 10.0147 8 12.5 8C14.3752 8 15.9824 9.14693 16.6586 10.7775C16.7146 10.9127 16.7644 11.0514 16.8073 11.1928C16.949 11.2358 17.0879 11.2856 17.2233 11.3418C18.8535 12.0182 20 13.6252 20 15.5Z"
        stroke={checked ? `${colors.primaryInverseText}` : `${colors.accentBackground}`}
        strokeWidth="1.2"
        style={{ transition: 'stroke 0.3s ease' }}
      />
    </svg>
  );
};
