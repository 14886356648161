import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ColumnItem } from './mock-columns';
import { useEffect, useState } from 'react';
import { IApartment } from '@/pages/roompicker-page/model/types';

interface HousingSelectorHeaderProps {
  columns: ColumnItem[];
  gridTemplateColumns: string;
  rows: IApartment[];
  displayedRows: IApartment[];
  setDisplayedRows: (param: IApartment[]) => void;
}
export const HousingSelectorHeader = ({
  columns,
  gridTemplateColumns,
  rows,
  displayedRows,
  setDisplayedRows,
}: HousingSelectorHeaderProps) => {
  const [sortOption, setSortOption] = useState<'default' | 'asc' | 'desc'>('default');
  const [sortColumn, setSortColumn] = useState<keyof IApartment>();

  const sortHandler = (column: ColumnItem) => {
    if (!column.sortable) return;
    const choiceOption = (sortOption: string) => {
      if (sortOption === 'asc') return 'desc';
      if (sortOption === 'desc') return 'default';
      return 'asc';
    };

    if (sortColumn === column.name) {
      setSortOption(choiceOption(sortOption));
      return;
    }
    setSortOption('asc');
    setSortColumn(column.name);
  };

  useEffect(() => {
    if (sortColumn && sortOption !== 'default') {
      const sortedRows = [...displayedRows].sort((a, b) => {
        const aValue = a[sortColumn] as string | number;
        const bValue = b[sortColumn] as string | number;

        if (aValue < bValue) {
          return sortOption === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortOption === 'asc' ? 1 : -1;
        }
        return 0;
      });
      setDisplayedRows(sortedRows);
      return;
    }
    setDisplayedRows(rows);
  }, [sortColumn, sortOption]);

  return (
    <div {...stylex.props(styles.headerGrid)} style={{ gridTemplateColumns }}>
      {columns.map(
        (column) =>
          !column.hidden && (
            <div
              {...stylex.props(column.hasArrows ? styles.tableHeaderWithIcons : styles.tableHeader(column.sortable))}
              key={column.id}
              role={column.sortable ? 'button' : ''}
              onClick={() => sortHandler(column)}
            >
              {column.renderCell ? (
                column.renderCell(column, styles.icon, sortOption, sortColumn, styles.headerText)
              ) : (
                <span {...stylex.props(styles.headerText)}>{column.value}</span>
              )}
            </div>
          )
      )}
    </div>
  );
};
