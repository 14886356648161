import * as stylex from '@stylexjs/stylex';
import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';

export const styles = stylex.create({
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '0.83333rem',
    alignSelf: 'stretch',
    width: '100%',
    touchAction: 'pan-y',

    '@media (max-width: 767px)': {
      gap: '2.13333rem',
    },
  },
  slider: {
    position: 'relative',
    width: '100%',
    height: '40.52083rem',
    overflow: 'hidden',

    '@media (max-width: 767px)': {
      height: '69.33333rem',
    },
  },
  sliderItem: (isCurrentIndex) => ({
    width: '100%',
    position: 'absolute',
    opacity: isCurrentIndex ? '1' : '0',
    transition: 'opacity 0.6s ease-in-out',
  }),
  image: {
    width: '100%',
    height: '40.52083rem',
    objectFit: 'cover',
    userSelect: 'none',

    '@media (max-width: 767px)': {
      height: '69.33333rem',
    },
  },
  containerOfNumberPage: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.20833rem',
    alignSelf: 'stretch',
    bottom: '1.25rem',
    left: '50%',
    transform: 'translateX(-50%)',
    userSelect: 'none',

    '@media (max-width: 767px)': {
      gap: '1.06667rem',
      bottom: '6.4rem',
    },
  },
  numberCurrentPage: {
    color: colors.primaryInverseText,
    fontFamily: fonts.din2014Regular,
    fontSize: '1.875rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '2.08333rem',

    '@media (max-width: 767px)': {
      fontSize: '4.8rem',
      lineHeight: '6.4rem',
    },
  },
  totalPage: {
    color: colors.primaryInverseText,
    fontFamily: fonts.din2014Regular,
    fontSize: '1.04167rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.25rem',

    '@media (max-width: 767px)': {
      fontSize: '3.73333rem',
      fontWeight: '300',
      lineHeight: '5.33333rem',
      fontFamily: fonts.din2014Light,
    },
  },
  leftButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '2.08333rem',
    width: '3.75rem',
    height: '3.75rem',
    borderRadius: '1000px',
    border: '0px',
    padding: '1.04167rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: colors.primaryBackground,
    ':hover': {
      opacity: '0.8',
    },
    transition: 'opacity 0.6s ease',
    ':active': {
      opacity: '0.2',
      transition: 'opacity 0.25s ease',
    },

    '@media (max-width: 767px)': {
      left: '2.13333rem',
      width: '11.73333rem',
      height: '11.73333rem',
      padding: '5.33333rem',
      ':hover': {
        opacity: '1',
      },
      ':active': {
        opacity: '0.2',
        transition: 'opacity 0.5s ease',
      },
    },
  },
  arrow: {
    width: '1.66667rem',
    height: '1.66667rem',
    color: colors.primaryIcon,

    '@media (max-width: 767px)': {
      width: '6.4rem',
      height: '6.4rem',
      flexShrink: '0',
    },
  },
  rigthButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '2.08333rem',
    width: '3.75rem',
    height: '3.75rem',
    borderRadius: '1000px',
    border: '0px',
    padding: '1.04167rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: colors.primaryBackground,
    transition: 'opacity 0.6s ease',
    ':hover': {
      opacity: '0.8',
    },
    ':active': {
      opacity: '0.2',
      transition: 'opacity 0.5s ease',
    },

    '@media (max-width: 767px)': {
      right: '2.13333rem',
      width: '11.73333rem',
      height: '11.73333rem',
      padding: '5.33333rem',
      ':hover': {
        opacity: '1',
      },
      ':active': {
        opacity: '0.2',
        transition: 'opacity 0.25s ease',
      },
    },
  },
  svgContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    zIndex: 1,

    '@media (max-width: 767px)': {
      width: '85%',
      height: '85%',
    },
  },
  svg: {
    width: '100%',
    height: '100%',
    overflow: 'visible',
  },
  captionContainer: {
    position: 'relative',
    width: '100%',
    height: '1.25rem',

    '@media (max-width: 767px)': {
      height: '8.533333rem',
    },
  },
  captionItem: (isCurrentIndex) => ({
    width: '100%',
    padding: '0rem 3.125rem',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    position: 'absolute',
    userSelect: 'none',
    opacity: isCurrentIndex ? '1' : '0',
    visibility: isCurrentIndex ? 'visible' : 'hidden',
    transition: 'opacity 0.5s ease-out, visibility 0.5s ease-out',
    transitionDelay: isCurrentIndex ? '0.5s' : '0s',

    '@media (max-width: 767px)': {
      padding: '0 0 0 4.26667rem',
    },
  }),
  caption: {
    color: colors.secondaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.25rem',

    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
      lineHeight: '4.26667rem',
    },
  },
});
