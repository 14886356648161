export const BackBtnArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10">
      <path
        d="M5.16667 9.375L1 5M1 5L5.16667 0.625M1 5H11"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
