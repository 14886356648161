import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  topBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  fullPriceBlock: {},
  pricePerMeter: {},
  price: {
    color: colors.primaryForeground,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '133.333%',
  },
  pricepPerMeterText: {
    color: colors.tertiaryForeground,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '142.857%',
  },
  primaryForegroundColor: {
    color: colors.primaryForeground,
  },
  tertiaryForegroundColor: {
    color: colors.tertiaryForeground,
  },
  currency: {
    color: colors.tertiaryForeground,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '133.333%',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  icon: {
    width: '36px',
    height: '36px',
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
});
