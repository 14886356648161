import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { PhotoFrameTextType } from '../model/types';
import useIsMobile from '@/shared/hooks/useIsMobile.tsx';

const PhotoFrameTextSection = ({ header, description, imgSrc, caption, reverseOrder }: PhotoFrameTextType) => {
  const { isMobile } = useIsMobile();

  const getImageSrc = () => {
    if (typeof imgSrc === 'string') {
      return imgSrc;
    }
    return imgSrc ? (isMobile ? imgSrc.mobile : imgSrc.desktop) : '';
  };
  return (
    <div {...stylex.props(styles.photoTextContainer, reverseOrder && styles.reverseOrder)}>
      <div {...stylex.props(styles.textContainer)}>
        {header && <h3 {...stylex.props(styles.h3)}>{header}</h3>}
        {description && <div {...stylex.props(styles.descriptionContainer)}>{description}</div>}
      </div>
      <div {...stylex.props(styles.photoFrameContainer)}>
        {imgSrc && (
          <img src={getImageSrc()} {...stylex.props(styles.img, reverseOrder && styles.reverseBorderImg)}></img>
        )}
        {caption && <div {...stylex.props(styles.captionContainer)}>{caption}</div>}
      </div>
    </div>
  );
};

export default PhotoFrameTextSection;
