import { CommonProps, ComponentConfig } from '@/shared/libs/dynamic-components-render.tsx';
import tulupovFrame from '@shared/ui/assets/tulupovFrame.png';
import { BEFORE_AFTER_PHOTO_INFO_MOCK } from '@/pages/realisation-page/model/realisation-page-mock-data';
import fasadMalayaOrdinka from '@shared/ui/assets/fasadMalayaOrdinka.png';
import clock from '@shared/ui/assets/clock.png';
import clockMob from '@shared/ui/assets/clockMob.png';

export const realisationPageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'PhotoFrameTextSection',
    props: {
      description:
        'Мы создаём комфортную и престижную среду. Заботимся о комфорте клиента на каждом этапе: при выборе объекта, во время покупки, ожидания ключей, на этапе жизни и работы в построенных домах.',
      imgSrc: tulupovFrame,
      caption: 'Алексей Тулупов, президент компании',
    },
  },
  {
    component: 'BeforeAfterSlider',
    props: {
      photosInfo: BEFORE_AFTER_PHOTO_INFO_MOCK,
    },
  },
  {
    component: 'PhotoFrameTextSection',
    props: {
      header: 'СОХРАНЯЕМ И ПРИУМНОЖАЕМ',
      description:
        'Мы гордимся каждым построенным зданием и\u00A0внимательно следим за тем, чтобы их внешний облик, содержание и сервисы соответствовали изначальной задумке. Следит за этим Служба комфорта Sminex. Её задача постоянно улучшать качество жизни в наших домах. Таким образом, наши дома, как хорошее вино, при должном обращении с годами становится только вкуснее и\u00A0дороже.',
      imgSrc: fasadMalayaOrdinka,
      caption: 'Фасад дома Малая Ордынка 19',
    },
  },
  {
    component: 'PhotoFrameTextSection',
    props: {
      header: 'СТАБИЛЬНОСТЬ И ПОРЯДОК ВО\u00A0ВСЁМ',
      description:
        'Мы системная, технологичная и ответственная компания. Мы ценим и исповедуем порядок и прозрачность во всём: на строительной площадке, в документах, в общении с клиентами, сотрудниками, подрядчиками. Мы предъявляем высочайшие требования к себе самим, своим партнёрам и создаваемым продуктам. Это позволяет нам всегда превосходить ожидания и добиваться превосходного результата.',
      imgSrc: { desktop: clock, mobile: clockMob },
      reverseOrder: true,
    },
  },
];
