import { IHistogramProps } from '@/enteties/histogram/model/types.ts';
import styles from '@/enteties/histogram/ui/styles.module.css';
import { colors } from '@/shared/tokens/colors.stylex.ts';

const Histogram = ({ infoProps = {}, histogramData }: IHistogramProps) => {
  const { minValue = 0, maxValue = 0, minSelectedValue = 0, maxSelectedValue = 0 } = infoProps;

  if (!histogramData) return null;

  const isChanged = minValue !== minSelectedValue || maxValue !== maxSelectedValue;
  const columnStep = (maxValue - minValue) / histogramData.length;

  return (
    <div className={styles.columnsContainer}>
      {histogramData.map((el, i) => {
        const columnHeightInPercents = Math.round(el * 100);
        const minPointerIsSmallerThanEndOfColumn = minSelectedValue < (i + 1) * columnStep + minValue;
        const maxPointerIsBiggerThanStartOfColumn = i * columnStep + minValue < maxSelectedValue;

        return (
          <div
            key={i}
            className={styles.oneColumn}
            style={{
              height: `${columnHeightInPercents}%`,
              backgroundColor:
                isChanged && minPointerIsSmallerThanEndOfColumn && maxPointerIsBiggerThanStartOfColumn
                  ? colors.brandBackground
                  : '',
            }}
          />
        );
      })}
    </div>
  );
};

export default Histogram;
