import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  cardsContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    backgroundColor: colors.brandGhostBackground,
  },
});
