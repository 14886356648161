export const HOUSING_SELECTOR_CONSTS = {
  ADD_TO_COMPARE: 'Добавить в сравнение',
  APARTMENT_PLAN: 'План квартиры',
  ADD_TO_FAVS: 'Добавить в избранное',
};

export const HOUSING_SELECTOR_MEASURES = {
  M2: 'м²',
  RUB: '₽',
  MILLION_RUB: 'млн. ₽',
};

export const HOUSING_SELECTOR_HEADER = {
  PROJECT: 'Проект',

  SCHEMA: 'Схема',

  BEDROOMS: 'Cпальни',

  SQUARE: 'Площадь',

  SIZE: 'Размер',

  TYPE: 'Тип',

  FLOOR: 'Этаж',

  BUILDING: 'Корпус',

  NUMBER: 'Номер',

  PRICEFORM: 'Цена за М²',

  PRICE: 'Стоимость',

  EMPTY_FOR_COMPARE: '',
};

export const LOT_CARDS_CONSTS = {
  BUILDING: 'Корпус',
  FLOOR: 'Эт.',
  FLOOR_FULL: 'Этаж',
  NUMBER: 'Номер',
  FLAT: 'Кв.',
  M2: 'м²',
  RUB: ' ₽',
  MILLION_RUB: ' млн. ₽',
  PRICE_FOR_METER: 'Цена за M² - ',
  DIVIDER: '•',
  BEDROOMS: 'спальни',
};

export const PHONE_NUMBER = '+7 495 324 72 11';
