import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { LotCard } from '@/enteties/lot-card';
import { IApartment } from '@/pages/roompicker-page/model/types';

export interface ILotCardsProps {
  rows: IApartment[];
}

export const LotCards = ({ rows }: ILotCardsProps) => {
  return (
    <div {...stylex.props(styles.cardsContainer)}>
      {rows.map((row) => (
        <LotCard key={row.id} row={row} />
      ))}
    </div>
  );
};
