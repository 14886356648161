import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  tableContainer: (width) => ({
    fontFamily: fonts.din2014Regular,
    // minWidth: width ? width : '989px',
  }),

  scrollContainer: {
    height: '100%',
    // overflowX: 'auto',
  },
});
