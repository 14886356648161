import React from 'react';
import { IDoubleRangeInput } from '@/enteties/double-input-range/model/types.ts';

import styles from './styles.module.css';
import { formatValue } from '../../lib/format-value';

const Title = ({ title }: { title?: string }) => {
  if (!title) return null;
  return <div className={styles.title}>{title}</div>;
};

const DoubleRangeInput = ({
  title,
  infoProps = {},
  minStep = 1,
  histogramComponent,
  onChangeMinHandler,
  onChangeMaxHandler,
}: IDoubleRangeInput) => {
  const { minValue = 0, maxValue = 0, minSelectedValue = 0, maxSelectedValue = 0 } = infoProps;
  const isChanged = minValue !== minSelectedValue || maxValue !== maxSelectedValue;

  const innerOnChangeMinHandler = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (!event) return;
    const value = parseFloat(Math.min(Number(event.target.value), maxSelectedValue - minStep).toFixed(10));
    onChangeMinHandler?.(value);
  };

  const innerOnChangeMaxHandler = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (!event) return;
    const value = parseFloat(Math.max(Number(event.target.value), minSelectedValue + minStep).toFixed(10));
    onChangeMaxHandler?.(value);
  };

  return (
    <>
      <div className={styles.outerContainer}>
        <Title title={title} />
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.sliderLeftValue}>
              <span className={styles.sliderValueText}>от </span>
              {formatValue(minSelectedValue)}
            </div>
            <div className={styles.sliderRightValue}>
              <span className={styles.sliderValueText}>до </span>
              {formatValue(maxSelectedValue)}
            </div>

            {histogramComponent}

            <div className={styles.doubleRangeContainer}>
              <div className={styles.doubleRangeWrapper}>
                <input
                  type="range"
                  min={minValue}
                  max={maxValue}
                  step={minStep}
                  value={minSelectedValue}
                  onChange={innerOnChangeMinHandler}
                  className={`${styles.leftThumb} ${isChanged ? '' : styles.nonActive} ${minSelectedValue > maxSelectedValue - minStep * 4 ? styles.zIndex5 : styles.zIndex4}`}
                />
                <input
                  type="range"
                  min={minValue}
                  max={maxValue}
                  step={minStep}
                  value={maxSelectedValue}
                  onChange={innerOnChangeMaxHandler}
                  className={`${styles.rightThumb} ${isChanged ? '' : styles.nonActive} ${styles.zIndex4}`}
                />
                <div className={styles.slider}>
                  <div className={styles.sliderTrack} />
                  <div
                    className={`${styles.sliderRange} ${isChanged ? '' : styles.nonActive}`}
                    style={{
                      left: `${((minSelectedValue - minValue) / (maxValue - minValue)) * 100}%`,
                      right: `${100 - ((maxSelectedValue - minValue) / (maxValue - minValue)) * 100}%`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoubleRangeInput;
