import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { IApartment } from '@/pages/roompicker-page/model/types';

export interface IModalBodyProps {
  apartment: IApartment | null;
  active: string;
}
export const ModalBody = ({ apartment, active }: IModalBodyProps) => {
  const mockImageSources: Record<string, string | undefined> = {
    'C мебелью': apartment?.schemaSrcFurniture,
    'С размерами': apartment?.schemaSrcSizes,
    'Без размеров': apartment?.schemaSrc,
  };
  return (
    <div {...stylex.props(styles.bodyWrapper)}>
      <div {...stylex.props(styles.body)}>
        <img src={mockImageSources[active]} {...stylex.props(styles.image)}></img>
      </div>
    </div>
  );
};
