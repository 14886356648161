import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { LOT_CARDS_CONSTS } from '@/shared/lib/consts';
import { IApartment } from '@/pages/roompicker-page/model/types';

export interface IBottomBlockProps {
  row: IApartment;
}
export const BottomBlock = ({ row }: IBottomBlockProps) => {
  return (
    <div {...stylex.props(styles.bottomBlock)}>
      <div {...stylex.props(styles.project, styles.tertiaryForegroundColor)}>
        <span>{row.building && `${LOT_CARDS_CONSTS.BUILDING} ${row.building} `}</span>
        <span>{`${LOT_CARDS_CONSTS.FLOOR_FULL} ${row.floor}, `}</span>
        <span>{`${LOT_CARDS_CONSTS.NUMBER} ${row.number}`}</span>
      </div>
      <div {...stylex.props(styles.project)}>{row.buildingName}</div>
    </div>
  );
};
