import { GlassPlusIcon } from '@/shared/ui/icons/glass-plus-icon.tsx';

import * as stylex from '@stylexjs/stylex';
import { styles } from './stylex.ts';
import { IApartment } from '@/pages/roompicker-page/model/types.ts';

const GlassIconWrapperID = 'GlassIconWrapper';

export const ImageSection = ({
  address,
  onClick,
  imgLink,
  infoProps,
  setSelectedApartment,
}: {
  address?: string;
  onClick?: () => void;
  imgLink?: string;
  infoProps: IApartment;
  setSelectedApartment: (item: IApartment) => void;
}) => {

  return (
    <button
      {...stylex.props([styles.imgWrapper, styles.btnDefaultStyleReset])}
      onClick={() => {
        onClick?.();
        setSelectedApartment(infoProps);
      }}
    >
      <img src={imgLink} alt={address} {...stylex.props(styles.img)} />
      <span {...stylex.props(styles.iconPlus)} id={GlassIconWrapperID}>
        <GlassPlusIcon />
      </span>
    </button>
  );
};
