import stylex, { StyleXStyles } from '@stylexjs/stylex';
import { PopoverZoomIn } from '@/shared/ui/popoover-zoom-in';
import { Popover } from '@/shared/ui/popover';
import { AddToCompareIcon } from '@/shared/ui/icons/add-to-compare-icon';
import { HOUSING_SELECTOR_CONSTS } from '@/shared/lib/consts';
import { AddToFavsIcon } from '@/shared/ui/icons/add-to-favs-icon';
import { IApartment } from '@/pages/roompicker-page/model/types';

export const renderImg = (row: IApartment, style: StyleXStyles, clickHandler: (e: React.MouseEvent) => void) => {
  return (
    <PopoverZoomIn
      children={<img {...stylex.props(style)} src={row.schemaSrc} alt={row.buildingName}></img>}
      handleClick={clickHandler}
    ></PopoverZoomIn>
  );
};

export const renderSquare = (row: IApartment, style: StyleXStyles) => {
  return (
    <>
      {row.area}
      <span {...stylex.props(style)}> {row.measure}</span>
    </>
  );
};

export const renderPriceForSquare = (row: IApartment, style: StyleXStyles) => {
  return (
    <>
      {row.value}
      <span {...stylex.props(style)}> {row.currency}</span>
    </>
  );
};

export const renderFullCost = (row: IApartment, style: StyleXStyles) => {
  return (
    <Popover
      children={
        <>
          {row.cost}
          <span {...stylex.props(style)}> {row.fullCurrency}</span>
        </>
      }
      content={`${row.price} ${row.currency}`}
    ></Popover>
  );
};

export const renderTableActions = (
  style: StyleXStyles,
  addToFavsHandler: (e: React.MouseEvent) => void,
  addToCompareHandler: (e: React.MouseEvent) => void,
  isAddedToFavs: boolean,
  isAddedToCompare: boolean
) => {
  return (
    <>
      <Popover
        children={
          <div {...stylex.props(style)} role="button" onClick={addToCompareHandler}>
            <AddToCompareIcon checked={isAddedToCompare} />
          </div>
        }
        content={HOUSING_SELECTOR_CONSTS.ADD_TO_COMPARE}
      ></Popover>
      <Popover
        children={
          <div {...stylex.props(style)} role="button" onClick={addToFavsHandler}>
            <AddToFavsIcon checked={isAddedToFavs}></AddToFavsIcon>
          </div>
        }
        content={HOUSING_SELECTOR_CONSTS.ADD_TO_FAVS}
        position="bottom-left"
      ></Popover>
    </>
  );
};
