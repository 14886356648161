import { useNavigate, useLocation } from 'react-router-dom';
import { tabMenuItems } from '../model/mock-items';
import styles from './tab-menu.module.css';
import { useEffect, useState } from 'react';
import { SelectionHeaderProps } from '../../selection-header/ui';
import { FilterActions } from '@/enteties/filter-actions';

interface TabMenuProps extends SelectionHeaderProps {
  setIsMenuVisible: (item: boolean) => void;
  isMenuVisible: boolean;
}

export const TabMenu = ({
  toggleFilterVisibility,
  isFilterVisible,
  setIsMenuVisible,
  isMenuVisible,
  apartsToShow,
  resetFilters,
}: TabMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string | null>(null); // Состояние для отслеживания активного элемента

  useEffect(() => {
    // Обновляем activeItem при изменении пути
    const matchingItem = tabMenuItems.find((item) => item.url === location.pathname);
    setActiveItem(matchingItem ? matchingItem.title : null);
  }, [location.pathname]);

  useEffect(() => {
    // Если меню закрыто, устанавливаем activeItem в текущую страницу
    const matchingItem = tabMenuItems.find((item) => item.url === location.pathname);
    setActiveItem(isMenuVisible ? 'Меню' : matchingItem ? matchingItem.title : null);
  }, [isMenuVisible, location.pathname]);

  const handleClick = (title: string, url?: string) => {
    setActiveItem(title);
    title === 'Меню' ? setIsMenuVisible(true) : setIsMenuVisible(false);
    url && navigate(url);
  };

  const onResetHandler = () => {
    toggleFilterVisibility();
    resetFilters();
  };

  return (
    <div
      className={`${isFilterVisible ? styles.filterContainer : styles.tabMenuContainer} ${isMenuVisible ? styles.clicked : ''}`}
    >
      {isFilterVisible ? (
        <div className={styles.mobileVisible}>
          <FilterActions
            onReset={onResetHandler}
            onShowResult={toggleFilterVisibility}
            apartsToCount={apartsToShow.length}
          />
        </div>
      ) : (
        <>
          {tabMenuItems.map((item, id) => {
            const IconComponent = item.btnIcon;
            const isActive = activeItem === item.title;
            return (
              <div
                className={styles.menuBtnWrap}
                key={id}
                onClick={() => {
                  handleClick(item.title, item.url);
                }}
              >
                <div className={`${styles.iconWrap} ${isActive ? styles.activeIcon : ''}`}>
                  <IconComponent />
                </div>
                <p className={`${isActive ? styles.activeText : styles.text}`}>{item.title}</p>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
