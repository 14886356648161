import { mockFhotos } from '@/features/slider-default/model/mock-slider-default';
import { CommonProps, ComponentConfig } from '@/shared/libs/dynamic-components-render';

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'SliderDefault',
    props: {
      images: mockFhotos,
    },
  },
];
