import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  selectionContainer: {
    display: 'flex',
    padding: '5.833rem 4.166rem',
    paddingBottom: '1.388rem',
    background: colors.brandGhostBackground,
    height: '100vh',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      padding: '4.444rem',
      paddingBottom: '26.667rem',
    },
  },

  desktopOnly: {
    display: 'none',
    '@media (min-width: 768px) ': {
      display: 'block',
      position: 'fixed',
      bottom: '2.778rem',
      width: '21.667rem',
      height: '4.167rem',
      padding: '0.833rem',
      background: colors.primaryBackground,
      borderRadius: '16px',
      zIndex: '200',
      boxShadow: '0px 0px 24px 0px rgba(29, 37, 45, 0.16)',
    },
  },
  filterWrap: (filterVisible: boolean) => ({
    // maxWidth: '21.667rem',
    width: '28.373rem',
    boxSizing: 'border-box',
    overflowY: 'auto',
    height: '100%',
    '@media (max-width: 767px)': {
      display: filterVisible ? '' : 'none',
      maxWidth: '100%',
      marginTop: filterVisible ? '11.06rem' : '27rem',
      width: 'unset',
    },
  }),

  checkBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: '0.833rem',
    backgroundColor: colors.primaryBackground,
    padding: '0.834rem',
    borderRadius: '0.834rem',
    gap: '1.667rem',
    '@media (max-width: 767px)': {
      marginTop: '3.333rem',
      padding: '3.333rem',
      borderRadius: '3.333rem',
      gap: '6.667rem',
    },
  },
  dopdownWrap: {
    background: '#fff',
    padding: '0.833rem',
    borderRadius: '0.833rem',
    '@media (max-width: 767px)': {
      padding: '3.333rem',
      borderRadius: '3.333rem',
    },
  },
  toggleSwitcherWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.111rem',
  },
  inputWrap: {},
});
