import stylex from '@stylexjs/stylex';
import { LOT_CARDS_CONSTS } from '@/shared/lib/consts';
import { styles } from './styles';
import { AddToCompareIcon } from '@/shared/ui/icons/add-to-compare-icon';
import { AddToFavsIcon } from '@/shared/ui/icons/add-to-favs-icon';
import { IApartment } from '@/pages/roompicker-page/model/types';

export interface ITopBlockProps {
  row: IApartment;
  addToFavsHandler: (e: React.MouseEvent) => void;
  isAddedToFavs: boolean;
  isAddedToCompare: boolean;
  addToCompareHandler: (e: React.MouseEvent) => void;
}
export const TopBlock = ({
  row,
  addToFavsHandler,
  isAddedToFavs,
  isAddedToCompare,
  addToCompareHandler,
}: ITopBlockProps) => {
  return (
    <div {...stylex.props(styles.topBlock)}>
      <div {...stylex.props(styles.priceBlock)}>
        <div {...stylex.props(styles.fullPriceBlock)}>
          <span {...stylex.props(styles.price)}>{row.cost}</span>
          <span {...stylex.props(styles.price, styles.tertiaryForegroundColor)}>{LOT_CARDS_CONSTS.MILLION_RUB}</span>
        </div>
        <div {...stylex.props(styles.pricePerMeter)}>
          <span {...stylex.props(styles.pricepPerMeterText)}>{LOT_CARDS_CONSTS.PRICE_FOR_METER}</span>
          <span {...stylex.props(styles.pricepPerMeterText, styles.primaryForegroundColor)}>{row.priceForSquare}</span>
          <span {...stylex.props(styles.pricepPerMeterText)}>{LOT_CARDS_CONSTS.RUB}</span>
        </div>
      </div>
      <div {...stylex.props(styles.icons)}>
        <div {...stylex.props(styles.icon)} role="button" onClick={addToCompareHandler}>
          <AddToCompareIcon checked={isAddedToCompare} />
        </div>

        <div {...stylex.props(styles.icon)} role="button" onClick={addToFavsHandler}>
          <AddToFavsIcon checked={isAddedToFavs} />
        </div>
      </div>
    </div>
  );
};
