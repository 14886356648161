export const Glass = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M16.875 16.8749L12.7288 12.7287M14.375 8.75C14.375 11.8566 11.8566 14.375 8.75 14.375C5.6434 14.375 3.125 11.8566 3.125 8.75C3.125 5.6434 5.6434 3.125 8.75 3.125C11.8566 3.125 14.375 5.6434 14.375 8.75Z"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
