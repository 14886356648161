import * as stylex from '@stylexjs/stylex';
import { styles } from '@/enteties/checkbox/ui/styles.ts';
import { ICheckBoxProps } from '@/enteties/checkbox/model/types.ts';

const CheckBox = ({ title, onChange, isChecked, isDisabled }: ICheckBoxProps) => {
  return (
    <label {...stylex.props([styles.button, !isDisabled && isChecked ? styles.activeBtn : null, isDisabled ? styles.disabledBtn : null])}>
      <input type="checkbox" checked={isChecked} onChange={onChange} {...stylex.props([styles.input])} />
      <span>{title}</span>
    </label>
  );
};

export default CheckBox;
