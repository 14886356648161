import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  itemList: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    ':hover > span': {
      backgroundColor: colors.primaryIcon,
      transition: 'background-color 0.4s',
      cursor: 'pointer',
      color: 'white',
    },
    '@media (max-width: 767px)': {
      // marginBottom: '4.26666667rem',
    },
  },
  round: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.04166667rem',
    borderRadius: '5.20833333rem',
    backgroundColor: colors.brandBackground,
    marginRight: '1.04166667rem',
    color: colors.primaryIcon,
    '@media (max-width: 767px)': {
      padding: '5.33333333rem',
      borderRadius: '26.6666667rem',
      marginRight: '5.33333333rem',
    },
  },

  point: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.20833333rem',
    '@media (max-width: 767px)': {
      gap: '1.06666667rem',
    },
  },

  documentPoint: {
    textDecoration: 'none',
    color: colors.primaryText,
    cursor: 'pointer',
    fontFamily: fonts.din2014Light,
    fontSize: '1.041667rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.45833333rem',
    alignSelf: 'stretch',
    '@media (max-width: 767px)': {
      fontSize: '4.26666667rem',
      lineHeight: '6.4rem',
    },
  },
  documentInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '2.08333333rem',
    '@media (max-width: 767px)': {
      gap: '10.6666667rem',
    },
  },
  documentSize: {
    color: colors.tertiaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.25rem',
    alignSelf: 'stretch',
    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
      lineHeight: '4.26666667rem',
    },
  },
  archiveButton: {
    color: colors.primaryText,
    fontFamily: fonts.din2014Light,
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.25rem',
    alignSelf: 'stretch',
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    textAlign: 'left',
    textDecoration: 'underline',
    '@media (max-width: 767px)': {
      fontSize: '3.2rem',
      lineHeight: '4.26666667rem',
    },
  },
});
