import * as stylex from '@stylexjs/stylex';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';
import { colors } from '@/shared/tokens/colors.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';
import { anatomy } from '@/shared/tokens/anatomy.stylex.ts';

export const styles = stylex.create({
  dropdown: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    fontFamily: fonts.din2014Regular,
    fontSize: '0.972rem',
    lineHeight: anatomy['size-20'],
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '@media (max-width: 767px)': {
      fontSize: '3.889rem',
    },
  },
  dropdownToggle: {
    width: '100%',
    height: '2.5rem',
    padding: '0.556rem 0.834rem',
    color: colors.primaryText,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '0.556rem',
    border: `1px solid ${colors.tertiaryBorder}`,
    background: palette.greyWhite,
    gap: '0.834rem',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    textAlign: 'left',
    transition: 'border-color 0.3s ease-in-out',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    '@media (max-width: 767px)': {
      height: '10rem',
      padding: '2.222rem 3.333rem',
      borderRadius: '2.222rem',
      gap: '3.333rem',
    },
  },
  dropdownLabel: {
    display: 'block',
    marginBottom: '0.834rem',
    fontSize: '0.833rem',
    fontFamily: fonts.din2014Light,
    fontWeight: '400',
    lineHeight: '100%',
    color: '#61666C',
    '@media (max-width: 767px)': {
      fontSize: '3.333rem',
      marginBottom: '1.111rem',
    },
  },
  dropdownToggleOpen: {
    border: `1px solid ${colors.accentBorder}`,
  },
  dropdownTextDefault: {
    color: '#8E9296',
  },
  dropdownText: (isOpen: boolean) => ({
    color: isOpen ? '#8E9296' : colors.primaryText,
    width: '100%',
  }),
  dropdownIcon: {
    width: '1.389rem',
    height: '1.389rem',
    minWidth: '1.389rem',
    minHeight: '1.389rem',
    transition: 'all 0.3s ease',
    '@media (max-width: 767px)': {
      width: '5.556rem',
      height: '5.556rem',
      minWidth: '5.556rem',
      minHeight: '5.556rem',
    },
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    border: `1px solid ${colors.tertiaryBorder}`,
    boxSizing: 'border-box',
    borderRadius: '0.556rem',
    marginTop: '0.556rem',
    listStyle: 'none',
    padding: '0.278rem',
    width: '100%',
    maxHeight: '13.056rem',
    overflowY: 'hidden',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    scrollbarWidth: 'thin',
    scrollbarColor: `${colors.accentBorder} ${colors.ghostBorder}`,
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: colors.ghostBackground,
      borderRadius: anatomy.borderRadiusRound,
      padding: '4px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: colors.accentBorder,
      borderRadius: '100px',
      height: '8px',
    },
    'scrollbar-width': 'thin',
    transition: 'all 0.5s ease',
    opacity: 1,
    zIndex: 10,
    visibility: 'visible',
    '@media (max-width: 767px)': {
      borderRadius: '2.222rem',
      marginTop: '2.222rem',
      padding: '1.111rem',
      maxHeight: '52.222rem',
    },
  },
  menuHidden: {
    opacity: 0,
    zIndex: -1,
    transform: 'TranslateY(-5%)',
    visibility: 'collapse',
  },
  dropdownItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.556rem 0.834rem',
    cursor: 'pointer',
    borderRadius: '0.278rem',
    transition: 'all 0.3s ease-out',
    color: colors.primaryText,
    '@media (hover: hover)': {
      ':hover': {
        backgroundColor: palette['darked-5'],
      },
    },
    '@media (max-width: 767px)': {
      padding: '2.222rem 3.333rem',
      borderRadius: '1.111rem',
    },
  },
  dropdownItemIcon: {
    display: 'flex',
    color: colors.accentIcon,
  },
  dropDownOpenIcon: {
    transform: 'rotate(180deg)',
  },
});
