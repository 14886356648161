import { FilterActionsProps } from '../model/filter-actions-type';
import styles from './filter-actions.module.css';

export const FilterActions = ({ onReset, onShowResult, apartsToCount }: FilterActionsProps) => {
  
  return (
    <div className={styles.filterMenuBtnWrap}>
      <button onClick={onShowResult} className={styles.largeBtn}>
        {`Показать ${apartsToCount} лотов`}
      </button>
      <button onClick={onReset} className={styles.smallBtn}>
        {`Сбросить`}
      </button>
    </div>
  );
};
