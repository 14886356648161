import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    padding: '0.278rem',
    alignItems: 'flex-start',
    gap: '0.278rem',
    borderRadius: '0.556rem',
    border: `1px solid ${colors.tertiaryBorder}`,
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      gap: '0.003rem',
    },
  },
  tab: (isActive) => ({
    display: 'flex',
    padding: '0.556rem 1.389rem',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.556rem',
    fontSize: '0.972rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '142.8%',
    color: isActive ? colors.primaryInverseForeground : colors.primaryInverseBackground,
    backgroundColor: isActive ? colors.primaryForeground : colors.primaryInverseForeground,
    borderRadius: '0.278rem',
    whiteSpace: 'nowrap',
    '@media (max-width: 768px)': {
      padding: '0.417rem 0.833rem',
      fontSize: '0.833rem',
      lineHeight: '100%',
    },
  }),
});
