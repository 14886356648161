import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { useState } from 'react';
import { BottomBlock } from './bottom-block';
import { MiddleBlock } from './middle-block';
import { TopBlock } from './top-block';
import { IApartment } from '@/pages/roompicker-page/model/types';

export interface ILotCardProps {
  row: IApartment;
}

export const LotCard = ({ row }: ILotCardProps) => {
  const [isAddedToFavs, setIsAddedToFavs] = useState<boolean>(row.isAddToFavs);
  const [isAddedToCompare, setIsAddedToCompare] = useState<boolean>(row.isAddToCompare);

  const addToFavsHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsAddedToFavs(!isAddedToFavs);
  };

  const addToCompareHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsAddedToCompare(!isAddedToCompare);
  };

  return (
    <div {...stylex.props(styles.lotCardWrapper)} onClick={() => {}} role="button">
      <TopBlock
        row={row}
        addToFavsHandler={addToFavsHandler}
        isAddedToFavs={isAddedToFavs}
        isAddedToCompare={isAddedToCompare}
        addToCompareHandler={addToCompareHandler}
      />
      <MiddleBlock row={row} />
      <BottomBlock row={row} />
    </div>
  );
};
