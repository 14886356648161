import { SortArrowIcon } from '@/shared/ui/icons/sort-arrow-icon';
import { SortArrowsIcon } from '@/shared/ui/icons/sort-arrows-icon';
import stylex, { StyleXStyles } from '@stylexjs/stylex';
import React from 'react';
import { IApartment } from '@/pages/roompicker-page/model/types';

export interface ColumnItem {
  id: number;
  name: string;
  value: string;
  sortable?: boolean;
  hasArrows?: boolean;
  hidden?: boolean;
  width?: string;
  renderCell?: (
    params: ColumnItem,
    style: StyleXStyles,
    sortOption: string,
    sortColumn: keyof IApartment | undefined,
    styleForSpan: StyleXStyles
  ) => React.ReactNode;
}
export const columns: ColumnItem[] = [
  {
    id: 1,
    name: 'buildingName',
    value: 'Проект',
    sortable: true,
    width: '11.5%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 2,
    name: 'schema',
    value: 'Схема',
    width: '6.5%',
  },
  {
    id: 3,
    name: 'bedroomCount',
    value: 'Cпальни',
    sortable: true,
    width: '7.7%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 4,
    name: 'area',
    value: 'Площадь',
    sortable: true,
    width: '8.1%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 5,
    name: 'size',
    value: 'Размер',
    sortable: true,
    width: '7.1%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>

        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 6,
    name: 'type',
    value: 'Тип',
    sortable: true,
    width: '11%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 7,
    name: 'floor',
    value: 'Этаж',
    sortable: true,
    width: '5.9%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 8,
    name: 'building',
    value: 'Корпус',
    sortable: true,
    width: '7%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 9,
    name: 'number',
    value: 'Номер',
    sortable: true,
    hasArrows: true,
    width: '6.7%',
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 10,
    name: 'priceForSquare',
    value: 'Цена за М²',
    sortable: true,
    hasArrows: true,
    width: '10.4%',
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 11,
    name: 'price',
    hasArrows: true,
    value: 'Стоимость',
    sortable: true,
    width: '10.4%',
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  { id: 12, name: 'empty', value: '', width: '7.7%' },
];

export const columnsShort: ColumnItem[] = [
  { id: 1, name: 'project', value: 'Проект', sortable: true, width: '1%', hidden: true },
  {
    id: 2,
    name: 'schema',
    value: 'Схема',
    width: '6.5%',
  },
  {
    id: 3,
    name: 'bedrooms',
    value: 'Cпальни',
    sortable: true,
    width: '7.7%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 4,
    name: 'square',
    value: 'Площадь',
    sortable: true,
    width: '8.1%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 5,
    name: 'size',
    value: 'Размер',
    sortable: true,
    width: '7.1%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 6,
    name: 'type',
    value: 'Тип',
    sortable: true,
    width: '11.1%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 7,
    name: 'floor',
    value: 'Этаж',
    sortable: true,
    width: '5.9%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 8,
    name: 'building',
    value: 'Корпус',
    sortable: true,
    width: '7%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 9,
    name: 'number',
    value: 'Номер',
    sortable: true,
    width: '6.7%',
    hasArrows: true,
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 10,
    name: 'priceForm',
    value: 'Цена за М²',
    sortable: true,
    hasArrows: true,
    width: '16.1%',
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  {
    id: 11,
    name: 'price',
    value: 'Стоимость',
    hasArrows: true,
    sortable: true,
    width: '16.1%',
    renderCell: (params, style, sortOption, sortColumn, styleForSpan) => (
      <>
        <span {...stylex.props(styleForSpan)}>{params.value}</span>
        <span {...stylex.props(style)}>
          {sortColumn === params.name && sortOption !== 'default' ? (
            <SortArrowIcon sortOption={sortOption} />
          ) : (
            <SortArrowsIcon />
          )}
        </span>
      </>
    ),
  },
  { id: 12, name: 'empty', value: '', width: '7.7%' },
];
