import * as stylex from '@stylexjs/stylex';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';

export const styles = stylex.create({
  input: {
    display: 'none',
  },
  checkboxGroupContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.556rem',
    '@media (max-width: 767px)': {
      gap: '2.222rem',
    },
  },
  checkboxGroupTitle: {
    fontFamily: fonts.din2014Regular,
    color: palette['grey-700'],
    fontSize: '0.834rem',
    lineHeight: '0.834rem',
    paddingBottom: '0.556rem',
    '@media (max-width: 767px)': {
      paddingBottom: '1.111rem',
      lineHeight: '3.333rem',
      fontSize: '3.333rem',
    },
  },
  container: {},
});
