import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  tableRow: {
    display: 'grid',
    width: '100%',
    height: '3.889rem',
    borderBottom: `1px solid ${colors.tertiaryBorder}`,
    ':hover': {
      backgroundColor: colors.ghostBackground,
      cursor: 'pointer',
    },
    userSelect: 'none',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    fontSize: '0.972rem',
    lineHeight: '143%',
    fontStyle: 'normal',
    color: colors.primaryText,
    fontWeight: 400,
    padding: '0.556rem',
    height: '3.889rem',
    wordBreak: 'break-all',
    '@media (max-width: 767px)': {
      fontSize: '3.889rem',
      padding: '2.22rem',
    },
  },

  tableCellSchema: {
    padding: 0,
    '@media (min-width: 1024px)': {
      padding: 0,
    },
  },
  tableCellUnitMeasure: {
    color: colors.tertiaryForeground,
    marginLeft: '0.139rem',
    '@media (min-width: 1024px)': {
      marginLeft: '0.19rem',
    },
  },
  tableCellImg: {
    width: '4.445rem',
    height: '3.889rem',
    objectFit: 'cover',

    // '@media (min-width: 1024px)': {
    //   width: '6.25rem',
    //   height: '5.46rem',
    // },
  },

  tableCellIcons: (hasProject) => ({
    display: 'flex',
    justifyContent: hasProject ? 'center' : '',
    gap: '0.278rem',
    '@media (min-width: 1024px)': {
      gap: '0.39rem',
      padding: '1.36rem 0',
    },
  }),
  icon: {
    width: '1.945rem',
    height: '1.945rem',
  },
});
