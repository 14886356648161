import { colors } from '@/shared/tokens/colors.stylex';
import React from 'react';

interface AddToFavsIconIconProps {
  checked: boolean;
}

export const AddToFavsIcon: React.FC<AddToFavsIconIconProps> = ({ checked }) => {
  return (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="28"
        height="28"
        rx="14"
        fill={checked ? `${colors.accentBackground}` : `${colors.brandGhostBackground}`}
        style={{ transition: 'fill 0.3s ease' }}
      />
      <path
        d="M17.0625 8.21494C17.7963 8.30009 18.3337 8.93267 18.3337 9.67132V20L14.0003 17.5L9.66699 20V9.67132C9.66699 8.93267 10.2044 8.30009 10.9381 8.21494C12.1614 8.07298 12.739 8 14.0003 8C15.2617 8 15.8393 8.07298 17.0625 8.21494Z"
        stroke={checked ? `${colors.primaryInverseText}` : `${colors.accentBackground}`}
        style={{ transition: 'stroke 0.3s ease' }}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
