
export function removeField<T extends object>(data: T | T[], fieldName: keyof T): T | T[] {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const { [fieldName]: _, ...rest } = item;
      return rest as T;
    });
  } else {
    const { [fieldName]: _, ...rest } = data;
    return rest as T;
  }
}