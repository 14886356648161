import React, { useMemo, useState } from 'react';
import { IntrSectionItem, IntrSectionProps, FileItem } from '../model/types';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { CardWithLinkIntroduction } from '@/enteties/card-with-link-introduction';
import { Modal } from '@/shared/ui/modal';
import { sortFilesByDate } from '../lib/sort-files-by-date';

const SectionIntroduction: React.FC<IntrSectionProps> = ({ sectionContent, text }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFiles, setCurrentFiles] = useState<FileItem[]>([]);
  const [currentType, setCurrentType] = useState<string>('');

  const sortedSectionContent = useMemo(() => {
    return sectionContent.map((section) => ({
      ...section,
      files: sortFilesByDate(section.files),
    }));
  }, [sectionContent]);

  const handleOpenArchive = (files: FileItem[], type: string) => {
    setCurrentFiles(files.slice(1));
    setCurrentType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentFiles([]);
    setCurrentType('');
  };

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.headerText)}>{text}</div>
      {sortedSectionContent.map((section: IntrSectionItem) => {
        const file = section.files[0];
        return (
          <div key={section.type}>
            <CardWithLinkIntroduction
              key={section.type}
              url={file.url}
              header={file.header}
              extension={file.extension}
              date={file.date}
              size={file.size}
              archive={section.files.length > 1 ? 'Архив' : undefined}
              filesCount={section.files.length}
              onArchiveClick={() => handleOpenArchive(section.files, section.type)}
            />
          </div>
        );
      })}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div {...stylex.props(styles.modalContent)}>
          <div {...stylex.props(styles.contentWrapper)}>
            <h2 {...stylex.props(styles.modalHeader)}>Архив: {currentType}</h2>
            {currentFiles.map((file, index) => (
              <CardWithLinkIntroduction
                key={`${currentType}-${index}`}
                url={file.url}
                header={file.header}
                extension={file.extension}
                date={file.date}
                size={file.size}
                filesCount={1}
                onArchiveClick={() => {}}
              />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SectionIntroduction;
