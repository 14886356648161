import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  headerGrid: {
    display: 'grid',
    width: '100%',
    backgroundColor: colors.brandGhostBackground,
  },
  tableHeader: (sortable) => ({
    fontSize: '0.834rem',
    lineHeight: '100%',
    fontStyle: 'normal',
    color: colors.tertiaryForeground,
    fontWeight: 400,
    textAlign: 'left',
    gap: '0.556rem',
    padding: '0.556rem',
    cursor: sortable ? 'pointer' : 'default',
    '@media (max-width: 767px)': {
      fontSize: '3.333rem',
      gap: '2.222rem',
      padding: '2.222rem',
    },
  }),

  tableHeaderWithIcons: {
    fontSize: '0.834rem',
    lineHeight: '100%',
    fontStyle: 'normal',
    color: colors.tertiaryForeground,
    fontWeight: 400,
    textAlign: 'left',
    gap: '0.278rem',
    padding: '0.556rem',
    display: 'flex',
    alignItems: 'flex-start',
    ':hover': {
      cursor: 'pointer',
    },
    '@media (max-width: 767px)': {
      fontSize: '3.333rem',
      gap: '1.111rem',
      padding: '2.222rem',
    },
  },
  headerText: {
    padding: '0.278rem 0',
    fontSize: '0.834rem',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.694rem',
    '@media (max-width: 767px)': {
      padding: '1.111rem 0',
      fontSize: '3.333rem',
      gap: '2.778rem',
    },
  },

  icon: {
    width: '.833rem',
    height: '.833rem',
    paddingBottom: '0.278rem',
    paddingTop: '0.278rem',
    ':not(#__unused__) > svg': {
      width: '.833rem',
      height: '.833rem',
    },
    '@media (max-width: 767px)': {
      width: '3.333rem',
      height: '3.333rem',
      paddingBottom: '1.111rem',
      paddingTop: '1.111rem',
    },
  },
});
