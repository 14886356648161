import { DoubleRangeInput } from '@/enteties/double-input-range';
import { Histogram } from '@/enteties/histogram';
import { useState } from 'react';
import { InputRangeProps } from '../model/types';

export const InputRange = ({ title, priceData, minStep, onRangeChange }: InputRangeProps) => {
  const { min: minValue = 0, max: maxValue = 0, histogram: histogramData = [] } = priceData;

  const [minSelectedValue, setMinSelectedValue] = useState(minValue);
  const [maxSelectedValue, setMaxSelectedValue] = useState(maxValue);

  const onChangeMinHandler = (value: number) => {
    setMinSelectedValue(value);
    onRangeChange(value, maxSelectedValue);
  };

  const onChangeMaxHandler = (value: number) => {
    setMaxSelectedValue(value);
    onRangeChange(minSelectedValue, value);
  };

  const infoProps = {
    minValue,
    maxValue,
    minSelectedValue,
    maxSelectedValue,
  };

  return (
    <DoubleRangeInput
      title={title}
      minStep={minStep}
      infoProps={infoProps}
      onChangeMinHandler={onChangeMinHandler}
      onChangeMaxHandler={onChangeMaxHandler}
      histogramComponent={<Histogram infoProps={infoProps} histogramData={histogramData} />}
    />
  );
};
