import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { HousingSelectorHeader } from './header';
import { HousingSelectorBody } from './body/ui';
import { ColumnItem } from './header/ui/mock-columns';
import { useEffect, useState } from 'react';
import { IApartment } from '@/pages/roompicker-page/model/types';

interface HousingSelectorGridProps {
  columns: ColumnItem[];
  rows: IApartment[];
  minWidth?: string;
  compareOnClickHandler?: (apartment: IApartment) => void;
}

export const HousingSelectorGrid = ({ columns, rows, minWidth, compareOnClickHandler }: HousingSelectorGridProps) => {
  const [displayedRows, setDisplayedRows] = useState<IApartment[]>(rows);

  useEffect(() => {
    if (rows) {
      setDisplayedRows(rows);
    }
  }, [rows]);

  const gridTemplateColumns = columns
    .filter((column) => !column.hidden)
    .map((column) => column.width || 'auto')
    .join(' ');
  return (
    <div {...stylex.props(styles.scrollContainer)}>
      <div {...stylex.props(styles.tableContainer(minWidth))} role="datagrid">
        <HousingSelectorHeader
          columns={columns}
          gridTemplateColumns={gridTemplateColumns}
          rows={rows}
          displayedRows={displayedRows}
          setDisplayedRows={setDisplayedRows}
        />
        <HousingSelectorBody
          rows={displayedRows}
          gridTemplateColumns={gridTemplateColumns}
          compareOnClickHandler={compareOnClickHandler}
        />
      </div>
    </div>
  );
};
