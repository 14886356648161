import * as stylex from '@stylexjs/stylex';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';
import { colors } from '@/shared/tokens/colors.stylex.ts';
import { palette } from '@/shared/tokens/palette.stylex.ts';
import { anatomy } from '@/shared/tokens/anatomy.stylex.ts';

export const styles = stylex.create({
  container: {
    fontFamily: fonts.din2014Regular,
    width: '16.25rem',
    height: '27.083rem',
    objectFit: 'cover',
    display: 'flex',
    padding: '1.112rem',
    flexDirection: 'column',
    gap: '0.834rem',
    borderRadius: '0.834rem',
    border: '1px solid',
    borderColor: colors.tertiaryBorder,
    backgroundColor: palette.greyWhite,
    boxSizing: 'border-box',
    ':hover': {
      background: 'linear-gradient(0deg, rgba(29, 37, 45, 0.05) 0%, rgba(29, 37, 45, 0.05) 100%), #FFF',
    },
    ':hover > button > img': {
      filter: 'brightness(0.92) contrast(1.1)',
    },
    '@media (max-width: 767px)': {
      padding: '4.445rem',
      gap: '3.334rem',
      width: '100%',
      height: '100.556rem',
      overflow: 'hidden',
      borderRadius: '3.334rem',
    },
  },
  desktopIconsContainer: {
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  mobileIconsContainer: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'flex',
      width: '100%',
    },
  },
  upperInfo: {
    width: '100%',
    display: 'flex',
    fontSize: '0.834rem',
    gap: '0.278rem',
    color: '#8E9296',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      width: '100%',
      fontSize: '3.334rem',
      gap: '1.1111rem',
    },
  },
  upperInfoText: {
    margin: 'auto 0',
    lineHeight: '100%',
    fontSize: '0.834rem',
    cursor: 'default',
    '@media (max-width: 767px)': {
      fontSize: '3.334rem',
    },
  },
  upperBuildingName: {
    width: '9.584rem',
    color: colors.primaryText,
    fontSize: '0.834rem',
    lineHeight: '100%',
    paddingBottom: '0.278rem',
    cursor: 'default',
    '@media (max-width: 767px)': {
      paddingBottom: '1.111rem',
      fontSize: '3.333rem',
      width: 'unset',
      maxWidth: '100%',
    },
  },
  textTruncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  upperInfoTextContainer: {
    margin: 'auto 0',
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  lowerClickableArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.834rem',
    fontSize: '0.834rem',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 0,
    border: 0,
    width: '100%',
    color: colors.primaryText,
    '@media (max-width: 767px)': {
      gap: '3.333rem',
      fontSize: '3.333rem',
    },
  },
  preLowerInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.278rem',
    '@media (max-width: 767px)': {
      gap: '1.1111rem',
    },
  },
  preLowerInfoPrice: {
    fontFamily: fonts.din2014Regular,
    fontWeight: 600,
    fontSize: '1.667rem',
    lineHeight: '2.222rem',
    '@media (max-width: 767px)': {
      fontSize: '7.778rem',
      lineHeight: '128.6%',
    },
  },
  preLowerInfoValue: {
    fontSize: '0.834rem',
    lineHeight: '100%',
    '@media (max-width: 767px)': {
      fontSize: '3.333rem',
    },
  },
  lowerInfo: {
    display: 'block',
    flexGrow: '1',
    width: '100%',
    fontSize: '0.972rem',
    lineHeight: '142.9%',
    '@media (max-width: 767px)': {
      display: 'inline',
      fontFamily: fonts.din2014Regular,
      fontWeight: 600,
      fontSize: '4.444rem',
      lineHeight: '125%',
    },
  },
  imgWrapper: {
    position: 'relative',
    height: '10%',
    width: '100%',
    padding: '1.111rem',
    boxSizing: 'border-box',
    flexGrow: 1,
    border: 0,
    overflow: 'hidden',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    ':hover > #GlassIconWrapper': {
      opacity: '1',
    },

    '@media (max-width: 767px)': {
      ':hover > #GlassIconWrapper': {
        display: 'none',
      },
      padding: '4.444rem',
    },
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },

  grayText: {
    color: '#8E9296',
  },
  pointDivider: {
    paddingHorizontal: '0.278rem',
    color: '#8E9296',
    '@media (max-width: 767px)': {
      color: '#1D252D',
      paddingHorizontal: '1.111rem',
    },
  },
  desktopHidden: {
    display: 'none',
    '@media (max-width: 767px)': {
      display: 'inline',
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: anatomy.borderRadiusRound,
    backgroundColor: {
      default: colors.brandGhostBackground,
      ':hover': colors.accentBackground,
    },
    color: {
      default: colors.accentIcon,
      ':hover': colors.primaryInverseText,
    },
    cursor: 'pointer',
    transition: 'background 0.3s ease, color 0.3s ease',
    border: 0,
  },
  iconMedium: {
    width: '1.944rem',
    height: '1.944rem',
    '@media (max-width: 767px)': {
      width: '7.778rem',
      height: '7.778rem',
    },
  },
  iconLarge: {
    width: '2.5rem',
    height: '2.5rem',
    '@media (max-width: 767px)': {
      width: '10rem',
      height: '10rem',
    },
  },
  iconsContainer: {
    display: 'flex',
    gap: '0.278rem',
    marginLeft: 'auto',
    justifyContent: 'end',
    flexBasis: 'fit-content',
    '@media (max-width: 767px)': {
      gap: '1.111rem',
    },
  },
  iconActive: {
    backgroundColor: colors.accentBackground,
    color: colors.primaryInverseText,
  },
  btnDefaultStyleReset: {
    fontFamily: fonts.din2014Regular,
    textIndent: 0,
    textDecoration: 0,
    textAlign: 'left',
    margin: 0,
    listStyle: 'none',
    borderSpacing: 0,
    border: 0,
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  iconPlus: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    borderRadius: anatomy.borderRadiusRound,
    width: '2.5rem',
    height: '2.5rem',
    backgroundColor: {
      default: colors.brandGhostBackground,
    },
    color: {
      default: colors.accentIcon,
    },
    transition: 'opacity 0.3s ease-in-out',
    '@media (max-width: 767px)': {
      width: '10rem',
      height: '10rem',
    },
  },
  paddingTop: {
    paddingTop: '0.278rem',
    '@media (max-width: 767px)': {
      paddingTop: '1.111rem',
    },
  },
});
