import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { DividerIcon } from '@/shared/ui/icons/divider-icon';
import { IApartment } from '@/pages/roompicker-page/model/types';

export interface IModalHeaderProps {
  apartment: IApartment | null;
}
export const ModalHeader = ({ apartment }: IModalHeaderProps) => {
  
  return (
    <div {...stylex.props(styles.header)}>
      <div {...stylex.props(styles.projectInfo)}>
        <div {...stylex.props(styles.projectInfoHeader)}>
          <div {...stylex.props(styles.apartmentType)}>{apartment?.type}</div>
          <div {...stylex.props(styles.apartmentDivider)}>
            <DividerIcon />
          </div>
          <div>
            <span {...stylex.props(styles.apartmentSizeText)}>{apartment?.square}</span>
            <span {...stylex.props(styles.apartmentSizeText, styles.apartmentSizeTextMeasure)}>
              {apartment?.measure}
            </span>
          </div>
        </div>
        <div {...stylex.props(styles.priceContainer)}>
          <span {...stylex.props(styles.projectPrice)}>{apartment?.cost}</span>
          <span {...stylex.props(styles.projectPrice, styles.projectPriceMeasure)}>{apartment?.fullCurrency}</span>
        </div>
      </div>
      <div {...stylex.props(styles.projectName)}>{apartment?.buildingName}</div>
    </div>
  );
};
