export const formatValue = (value: number): string => {

  if (value >= 10000) {
    // Округление до тысяч
    return (value / 1000).toFixed(0);
  }

  // Если меньше 10000, оставляем как есть
  return value.toString();
};
