import { useEffect, useState } from 'react';
import CheckBox from '@/enteties/checkbox/ui';
import { enrichFilterItems } from '@/enteties/checkbox-group/lib';
import { ICheckboxFilterItem, ICheckBoxProps } from '@/enteties/checkbox-group/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/enteties/checkbox-group/ui/styles.ts';

interface CheckBoxGroupProps extends ICheckBoxProps {
  onFilterChange?: (filterValue: string) => void | undefined;
  selectedIds: number[];
}

const CheckBoxGroup = ({
  title,
  filters,
  onCheckboxClick,
  sortLogic,
  onFilterChange,
  selectedIds,
}: CheckBoxGroupProps) => {
  const [filtersState, setFiltersState] = useState<ICheckboxFilterItem[]>(enrichFilterItems(filters));

  useEffect(() => {
    // Обновление состояния для сортировки
    if (sortLogic) {
      setFiltersState((prev) => [...prev]?.sort((a, b) => sortLogic.indexOf(a.id) - sortLogic.indexOf(b.id)));
    }
  }, [sortLogic]);

  useEffect(() => {
    // При вызове `resetFilters` состояние фильтров сбрасывается
    selectedIds!.length === 0 && setFiltersState(enrichFilterItems(filters));
  }, [selectedIds]);

  const handleCheckboxChange = (item: ICheckboxFilterItem) => {
    setFiltersState((prev) => prev.map((el) => (el.id === item.id ? { ...el, checked: !el.checked } : el)));

    // Обновляем временное состояние выбранных фильтров
    onFilterChange && onFilterChange(item.title);

    onCheckboxClick?.(item.id);
  };
  if (!title && (!filters || filters.length > 0)) return null;

  return (
    <div {...stylex.props([styles.container])}>
      {Boolean(title) && <div {...stylex.props([styles.checkboxGroupTitle])}>{title}</div>}
      <div {...stylex.props([styles.checkboxGroupContainer])}>
        {filtersState?.map((item) => (
          <CheckBox
            key={item.id}
            title={item.title}
            isChecked={item.checked}
            onChange={() => {
              handleCheckboxChange(item);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckBoxGroup;
