export const MOCK_FILTERS_PROJECTS = {
  id: 1,
  title: 'Проект',
  filters: [
    {
      id: 101,
      title: 'Фрунзенская набережная',
    },
    {
      id: 102,
      title: 'Лаврушинский',
    },
    {
      id: 103,
      title: 'Обыденский №1',
    },
    {
      id: 104,
      title: 'Ильинка 3/8',
    },
    {
      id: 105,
      title: 'Чистые пруды',
    },
    {
      id: 106,
      title: 'Тишинский бульвар',
    },
    {
      id: 107,
      title: 'Life Time',
    },
    {
      id: 108,
      title: 'West Garden',
    },
    {
      id: 109,
      title: 'Достижение',
    },
    {
      id: 110,
      title: 'Врубеля 4',
    },
  ],
};

export const MOCK_FILTERS_BEDROOMS_COUNT = {
  id: 2,
  title: 'Количество спален',
  filters: [
    {
      id: 201,
      title: 'Студия',
    },
    {
      id: 202,
      title: '1',
    },
    {
      id: 203,
      title: '2',
    },
    {
      id: 204,
      title: '3',
    },
    {
      id: 205,
      title: '4',
    },
    {
      id: 206,
      title: '5',
    },
  ],
};

export const MOCK_FILTERS_BEDROOMS_AREA = {
  id: 3,
  title: 'Размер спален',
  filters: [
    {
      id: 300,
      title: 'XS',
    },
    {
      id: 301,
      title: 'S',
    },
    {
      id: 302,
      title: 'M',
    },
    {
      id: 303,
      title: 'L',
    },
    {
      id: 304,
      title: 'XL',
    },
    {
      id: 305,
      title: 'XXL',
    },
  ],
};

export const MOCK_FILTERS_TYPE = {
  id: 4,
  title: 'Тип',
  filters: [
    {
      id: 401,
      title: 'Квартира',
    },
    {
      id: 402,
      title: 'Апартаменты',
    },
    {
      id: 403,
      title: 'High Level',
    },
    {
      id: 404,
      title: 'Penthouse',
    },
    {
      id: 405,
      title: 'Вилла',
    },
    {
      id: 406,
      title: 'Ситихаус',
    },
  ],
};

export const MOCK_FILTERS_LEVEL_COUNT = {
  id: 5,
  title: 'Количество уровней в квартире',
  filters: [
    {
      id: 501,
      title: 'Одноуровневые',
    },
    {
      id: 502,
      title: 'Двухуровненвые',
    },
    {
      id: 503,
      title: 'Трёхуровневые',
    },
  ],
};

export const MOCK_FILTERS_HOUSING = {
  id: 6,
  title: 'Корпус',
  filters: [
    {
      id: 601,
      title: 'A',
    },
    {
      id: 602,
      title: 'B',
    },
    {
      id: 603,
      title: 'C',
    },
    {
      id: 604,
      title: 'D',
    },
    {
      id: 605,
      title: 'E',
    },
    {
      id: 606,
      title: 'F',
    },
  ],
};

export const MOCK_FILTERS_ENTRANCE = {
  id: 7,
  title: 'Подъезд',
  filters: [
    {
      id: 701,
      title: '1',
    },
    {
      id: 702,
      title: '2',
    },
    {
      id: 703,
      title: '3',
    },
    {
      id: 704,
      title: '4',
    },
    {
      id: 705,
      title: '5',
    },
    {
      id: 706,
      title: '6',
    },
  ],
};

export const 
MOCK_FILTERS_ADVANTAGES = {
  id: 8,
  title: 'Преимущества',
  filters: [
    {
      id: 801,
      title: 'Малированные окна',
    },
    {
      id: 802,
      title: 'Балкон',
    },
    {
      id: 803,
      title: 'Терасса',
    },
    {
      id: 804,
      title: 'Лоджия',
    },
    {
      id: 805,
      title: 'Эркер',
    },
    {
      id: 806,
      title: 'Патио',
    },
    {
      id: 807,
      title: 'Камин',
    },
    {
      id: 808,
      title: 'Видовая',
    },
    {
      id: 809,
      title: 'Бассейн',
    },
    {
      id: 810,
      title: 'Отдельный вход',
    },
  ],
};

export const MOCK_FILTERS_KITCHEN_TYPE = {
  id: 9,
  title: 'Тип кухни',
  filters: [
    {
      id: 901,
      title: 'Гостиная',
    },
    {
      id: 902,
      title: 'Изолированая',
    },
  ],
};

export const MOCK_FILTERS_PLAN = {
  id: 10,
  title: 'Планировка',
  filters: [
    {
      id: 1001,
      title: 'На две стороны',
    },
    {
      id: 1002,
      title: 'На две смежных стороны',
    },
    {
      id: 1003,
      title: 'На три стороны',
    },
    {
      id: 1004,
      title: 'На одну сторону',
    },
    {
      id: 1005,
      title: 'Панорамная',
    },
  ],
};

export const MOCK_FILTERS_ORIENTATION = {
  id: 11,
  title: 'Ориентация по сторонам света',
  filters: [
    {
      id: 1101,
      title: 'Восток',
    },
    {
      id: 1102,
      title: 'Запад',
    },
    {
      id: 1103,
      title: 'Север',
    },
    {
      id: 1104,
      title: 'Юг',
    },
    {
      id: 1105,
      title: 'Юго-запад',
    },
    {
      id: 1106,
      title: 'Северо-запад',
    },
    {
      id: 1107,
      title: 'Северо-восток',
    },
    {
      id: 1108,
      title: 'Юго-восток',
    },
  ],
};

export const MOCK_FILTERS_INNER_YARD = {
  id: 12,
  title: 'Внутренний двор',
  filters: [
    {
      id: 1201,
      title: 'Кленовый',
    },
    {
      id: 1202,
      title: 'Ивовый',
    },
    {
      id: 1203,
      title: 'Рябиновый',
    },
  ],
};

export const MOCK_FILTERS_MANSION_TYPE = {
  id: 13,
  title: 'Виды особняков',
  filters: [
    {
      id: 1301,
      title: 'Вариант 1',
    },
    {
      id: 1302,
      title: 'Вариант 2',
    },
    {
      id: 1303,
      title: 'Вариант 3',
    },
    {
      id: 1304,
      title: 'Лев',
    },
    {
      id: 1305,
      title: 'Птица Сирин',
    },
    {
      id: 1306,
      title: 'Пардус',
    },
    {
      id: 1307,
      title: 'Грифон',
    },
  ],
};

export const MOCK_FILTERS_WINDOW_VIEW = {
  id: 14,
  title: 'Виды из окон',
  filters: [
    {
      id: 1401,
      title: 'Север',
    },
    {
      id: 1402,
      title: 'Юг',
    },
    {
      id: 1403,
      title: 'Восток',
    },
    {
      id: 1404,
      title: 'Запад',
    },
    {
      id: 1405,
      title: 'Двор-парк',
    },
    {
      id: 1406,
      title: 'Москва-сити',
    },
    {
      id: 1407,
      title: 'Малый сад',
    },
  ],
};

export const SORTING_LOGIC: { [key: number]: number[] } = {
  0: [101, 102, 103, 104, 105, 106, 107, 108, 109, 110],
  101: [102, 103, 104, 105, 106, 107, 108, 109, 110],
  102: [101, 103, 104, 105, 106, 107, 108, 109, 110],
  103: [104, 105, 102, 101, 106, 107, 108, 109, 110],
  104: [103, 105, 102, 101, 106, 107, 108, 109, 110],
  105: [104, 103, 102, 101, 106, 107, 108, 109, 110],
  106: [107, 102, 101, 108, 104, 103, 105, 109, 110],
  107: [106, 109, 108, 102, 101, 104, 103, 105, 110],
  108: [107, 109, 106, 102, 101, 103, 105, 104, 110],
  109: [108, 107, 106, 102, 101, 105, 104, 103, 110],
};
