import { ISortOption } from '@/shared/model/types';

export const selectionPropertyTypeOptions: ISortOption[] = [
  { id: 1, title: 'Жилая', key: 'residential' },
  { id: 2, title: 'Коммерческая', key: 'commercial' },
  { id: 3, title: 'Офисы', key: 'offices' },
  { id: 4, title: 'Машино-места', key: 'parking_spaces' },
  { id: 5, title: 'Келлеры', key: 'cellars' },
];

export const priceData = [
  {
    title: 'Этаж',
    category: 'floorRange',
    data: {
      min: 1,
      max: 45,
      histogram: [
        0.5, 0.7, 0.7, 0.1, 0.4, 0.1, 0.7, 0.7, 0.5, 0.5, 0.7, 0.3, 0.6, 0.7, 0.4, 0.1, 0.1, 0.7, 0.3, 0.1, 0.1, 0.4,
        0.1, 0.6, 0.3, 0.5, 0.4,
      ],
    },
  },
  {
    title: 'Площадь, м2',
    category: 'areaRange',
    data: {
      min: 25,
      max: 677,
      histogram: [
        0.5, 0.7, 0.7, 0.1, 0.4, 0.1, 0.7, 0.7, 0.5, 0.5, 0.7, 0.3, 0.6, 0.7, 0.4, 0.1, 0.1, 0.7, 0.3, 0.1, 0.1, 0.4,
        0.1, 0.6, 0.3, 0.5, 0.4,
      ],
    },
  },
  {
    title: 'Стоимость, млн ₽',
    category: 'priceRange',
    data: {
      min: 19,
      max: 2187,
      histogram: [
        0.5, 0.7, 0.7, 0.1, 0.4, 0.1, 0.7, 0.7, 0.5, 0.5, 0.7, 0.3, 0.6, 0.7, 0.4, 0.1, 0.1, 0.7, 0.3, 0.1, 0.1, 0.4,
        0.1, 0.6, 0.3, 0.5, 0.4,
      ],
    },
  },
  {
    title: 'Цена, тыс. ₽/м2',
    category: 'pricePerSquareRange',
    data: {
      min: 540000,
      max: 64400220,
      histogram: [
        0.5, 0.7, 0.7, 0.1, 0.4, 0.1, 0.7, 0.7, 0.5, 0.5, 0.7, 0.3, 0.6, 0.7, 0.4, 0.1, 0.1, 0.7, 0.3, 0.1, 0.1, 0.4,
        0.1, 0.6, 0.3, 0.5, 0.4,
      ],
    },
  },
  {
    title: 'Высота потолка, м',
    category: 'ceilingHeightRange',
    data: {
      min: 3,
      max: 10,
      histogram: [0.5, 0.7, 0.6, 0.3, 0.5, 0.4],
    },
  },
];

export const minStep = 10;
