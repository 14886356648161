import { useEffect, useMemo, useState } from 'react';
import { Dropdown } from '@/shared/ui/components/dropdown';
import { RoompickerHeader } from '@/features/roompicker-header';
import { RoompickerGridCard } from '@/features/roompicker-grid-card';
import { sortCallbackHandler } from '@/pages/roompicker-page/lib/sorting.ts';
import { IApartment, roompickerSortKeys } from '@/pages/roompicker-page/model/types.ts';
import { ISortOption } from '@/shared/model/types.ts';

import * as stylex from '@stylexjs/stylex';
import { styles } from '@/pages/roompicker-page/ui/styles.ts';

import { APARTMENTS_MOCK_DATA, DEFAULT_DROPDOWN_SORTING_OPTIONS } from '@/pages/roompicker-page/api/mock-data.ts';
import { HousingSelectorGrid } from '@/features/data-grid';
import { columns, columnsShort } from '@/features/data-grid/ui/header/ui/mock-columns';
import { LotCards } from '@/features/lot-cards';
import useIsMobile from '@/shared/hooks/useIsMobile';
import { useOutletContext } from 'react-router-dom';
import { SelectionContextType } from '@/pages/selection/model/types';
import { countSelectedFilters } from '../lib/filterBadgeCount';
import { removeField } from '../lib/removeProject';

interface RoompickerPageProps {
  toggleFilterVisibility: () => void;
  isFilterVisible: boolean;
  selectedFilters: { [key: string]: string[] };
}

const RoompickerPage = ({ toggleFilterVisibility, isFilterVisible, selectedFilters }: RoompickerPageProps) => {
  const [apartments, setApartments] = useState<IApartment[]>([]);
  const [isGridRow, setIsGridRow] = useState<boolean>(true);
  const [sort, setSort] = useState<ISortOption | null>(DEFAULT_DROPDOWN_SORTING_OPTIONS?.[3]);
  const { apartsToShow, setApartsToShow } = useOutletContext<SelectionContextType>();
  const { isMobile } = useIsMobile();

  const isSingleProjectSelected = useMemo(() => selectedFilters.projects?.length === 1, [selectedFilters.projects]);
  const filteredApartments = useMemo(() => {
    return apartments.filter((apartment) => {
      return Object.entries(selectedFilters).every(([key, values]) => {
        if (!values.length) return true;
        switch (key) {
          case 'projects':
            return values.includes(apartment.buildingName!);
          case 'bedrooms':
            return values.includes(String(apartment.bedroomCount));
          case 'area':
            return values.includes(apartment.size);
          case 'type':
            return values.includes(String(apartment.type));
          case 'housing':
            return values.includes(apartment.building || '');
          case 'apartmentNumber':
            return values.some((number) => String(apartment.number).toLowerCase() === number.toLowerCase());
          case 'floorRange': {
            const [minFloor, maxFloor] = values.map(Number);
            return Number(apartment.floor) >= minFloor && Number(apartment.floor) <= maxFloor;
          }
          case 'areaRange': {
            const [minArea, maxArea] = values.map(Number);
            return Number(apartment.area) >= minArea && Number(apartment.area) <= maxArea;
          }
          case 'priceRange': {
            const [minPrice, maxPrice] = values.map(Number);
            const cost =
              typeof apartment.cost === 'string'
                ? parseFloat(apartment.cost.replace(',', '.'))
                : Number(apartment.cost);
            return cost >= minPrice && cost <= maxPrice;
          }
          case 'pricePerSquareRange': {
            const [minPricePerSq, maxPricePerSq] = values.map(Number);
            const priceForSquare = apartment.value;

            return priceForSquare >= minPricePerSq && priceForSquare <= maxPricePerSq;
          }
          case 'ceilingHeightRange': {
            const [minCeilingHeight, maxCeilingHeight] = values.map(Number);
            return apartment.ceillingHigh! >= minCeilingHeight && apartment.ceillingHigh! <= maxCeilingHeight;
          }
          default:
            return true;
        }
      });
    });
  }, [apartments, selectedFilters]);
  // Функция для сортировки и установки `apartsToShow`
  const applySortAndSetApartsToShow = (data: IApartment[]) => {
    const sortedData = [...data].sort(sortCallbackHandler(sort?.key as roompickerSortKeys));
    setApartsToShow(sortedData);
  };
 
  const filterBadgeCount = useMemo(() => countSelectedFilters(selectedFilters), [selectedFilters]);

  useEffect(() => {
    setApartsToShow([...apartments].sort(sortCallbackHandler(sort?.key as roompickerSortKeys)));
  }, [apartments, sort?.key]);

  useEffect(() => {
    setApartments(APARTMENTS_MOCK_DATA.map((el) => ({ ...el, isFavorite: false, isToCompare: false })));
  }, []);
  useEffect(() => {
    setApartsToShow(filteredApartments);
  }, [filteredApartments]);

  const toCompareCount = useMemo(() => apartments.filter((apartment) => apartment.isToCompare).length, [apartments]);

  // Обработчик для добавления/удаления из сравнения
  const compareOnClickHandler = (apartment: IApartment) => {
    setApartments((prev) => {
      const updated = prev.map((item) =>
        item.id === apartment.id ? { ...item, isToCompare: !item.isToCompare } : item
      );
      applySortAndSetApartsToShow(updated);
      return updated;
    });
  };

  // Обработчик для добавления/удаления из избранного
  const favoriteOnClickHandler = (apartment: IApartment) => {
    setApartments((prev) => {
      const updated = prev.map((item) => (item.id === apartment.id ? { ...item, isFavorite: !item.isFavorite } : item));
      applySortAndSetApartsToShow(updated);
      return updated;
    });
  };

  // Применяем сортировку при изменении `apartments` или `sort`
  useEffect(() => {
    applySortAndSetApartsToShow(apartments);
  }, [apartments, sort]);

  return (
    <div {...stylex.props(styles.moreContainer(isFilterVisible))}>
      <div {...stylex.props(styles.container)}>
        {!isFilterVisible && (
          <div {...stylex.props(styles.headerWrapper)}>
            <RoompickerHeader
              isGridRow={isGridRow}
              compareBadgeCount={toCompareCount}
              filterBadgeCount={filterBadgeCount}
              gridOnClick={() => setIsGridRow(true)}
              rowOnClick={() => setIsGridRow(false)}
              dropdownOnSelect={(option) => setSort(option)}
              compareOnClick={toggleFilterVisibility}
            />
          </div>
        )}
        <div {...stylex.props(styles.cardsContainer(isFilterVisible, isGridRow))}>
          {/* На мобильной версии выпадающее меню является частью страницы, а не хедера */}
          <div {...stylex.props(styles.desktopHide(isFilterVisible))}>
            <Dropdown options={DEFAULT_DROPDOWN_SORTING_OPTIONS} onSelect={(option) => setSort(option)} />
          </div>
          {isGridRow ? (
            apartsToShow.map((apartment, index) => (
              <RoompickerGridCard
                key={index}
                infoProps={
                  isSingleProjectSelected
                    ? (removeField(apartment as IApartment, 'buildingName') as IApartment)
                    : apartment
                }
                isToComparison={apartment.isToCompare}
                isFavorite={apartment.isFavorite}
                onCompareClick={() => compareOnClickHandler(apartment)}
                onFavoriteClick={() => favoriteOnClickHandler(apartment)}
              />
            ))
          ) : !isMobile ? (
            <HousingSelectorGrid
              columns={isSingleProjectSelected ? columnsShort : columns}
              rows={
                isSingleProjectSelected
                  ? (removeField(apartsToShow as IApartment[], 'buildingName') as IApartment[])
                  : apartsToShow
              }
              compareOnClickHandler={compareOnClickHandler}
            />
          ) : (
            <>
              <LotCards rows={apartsToShow} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoompickerPage;
