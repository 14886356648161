import { useState } from 'react';
import styles from './currency-switcher.module.css';

const currencies = ['₽', '$', '€', '¥'];

export const CurrencySwitcher = () => {
  const [selectedCurrency, setSelectedCurrency] = useState('₽');

  const handleCurrencyClick = (currency: string) => {
    setSelectedCurrency(currency);
  };

  return (
    <div className={styles.currencySwitcher}>
      <label className={styles.currencyLabel}>Валюта</label>
      <div className={styles.currencyOptions}>
        {currencies.map((currency) => (
          <button
            key={currency}
            className={`${styles.currencyOption} ${selectedCurrency === currency ? styles.selected : ''}`}
            onClick={() => handleCurrencyClick(currency)}
          >
            {currency}
          </button>
        ))}
      </div>
    </div>
  );
};
