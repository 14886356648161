export interface ISortArrowIcon {
  sortOption: string;
}

export const SortArrowIcon = ({ sortOption }: ISortArrowIcon) => {
  return sortOption === 'asc' ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
      <path
        d="M7.73167 0H0.268334C0.0444792 0 -0.0805157 0.24439 0.0581151 0.41124L3.78978 4.88554C3.89659 5.01362 4.10227 5.01362 4.21022 4.88554L7.94188 0.41124C8.08052 0.24439 7.95552 0 7.73167 0Z"
        fill="#61666C"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
      <path
        d="M7.94188 4.57036L4.21022 0.0960542C4.1034 -0.0320181 3.89773 -0.0320181 3.78978 0.0960542L0.0581151 4.57036C-0.0805157 4.7372 0.0444792 4.9816 0.268334 4.9816H7.73167C7.95552 4.9816 8.08052 4.7372 7.94188 4.57036Z"
        fill="#61666C"
      />
    </svg>
  );
};
