import * as stylex from '@stylexjs/stylex';
import { colors } from '@/shared/tokens/colors.stylex.ts';

export const styles = stylex.create({
  container: {
    width: '100%',
    backgroundColor: colors.brandGhostBackground,
    paddingLeft: '1.667rem',

    '@media (max-width: 767px)': {
      paddingTop: '7.35rem',
      paddingLeft: '0',
    },
  },
  desktopHide: (isFilterVisible) => ({
    '@media (min-width: 768px)': {
      display: 'none',
    },
    paddingTop: isFilterVisible ? '4rem' : '22.222rem',
    paddingBottom: '8px',
  }),
  cardsContainer: (isFilterVisible, isGridRow) => ({
    // paddingLeft: '1.111rem',
    display: 'grid',
    objectFit: 'cover',
    gridTemplateColumns: isGridRow ? 'repeat(4, 1fr)' : 'repeat(auto-fit, minmax(16.25rem, 1fr))',
    gap: '1.111rem',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      display: isFilterVisible ? 'none' : 'grid',
      gridTemplateColumns: '1fr',
      gap: '8px',
      paddingLeft: '0',
    },
  }),
  moreContainer: (isFilterVisible) => ({
    width: '100%',
    backgroundColor: colors.brandGhostBackground,
    overflowY: 'auto',
    // height: '100%',
    '@media (max-width: 767px)': {
      width: isFilterVisible ? '' : '100%',
    },
  }),
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    // paddingLeft: '1.111rem',
    '@media (max-width: 767px)': {
      paddingHorizontal: '0px',
      paddingLeft: '0',
      position: 'fixed',
      left: '0',
      width: '100%',
      zIndex: '50',
    },
  },
});
