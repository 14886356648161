import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  middleBlock: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '4px',
  },

  middleBlockText: {
    color: colors.primaryForeground,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '125%',
  },
  tertiaryForegroundColor: {
    color: colors.tertiaryForeground,
  },
});
