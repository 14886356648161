import { CURRENCY_ENUM } from '@/shared/model/roompicker.ts';
import { ISortOption } from '@/shared/model/types.ts';
import apartmentplan from '@shared/ui/assets/apartment-plan.png';
import apartmentplanfurniture from '@shared/ui/assets/apartment-plan-furniture.png';
import apartmentplansizes from '@shared/ui/assets/apartment-plan-sizes.png';
import APARTMENT_IMAGE_LINK from '@/shared/ui/assets/roompicker/apartment-1.png';

export const BADGE_COUNT_TEST_ELEVEN = 11;

export const APARTMENTS_MOCK_DATA = [
  {
    id: 1,
    buildingName: 'Квартал Jazz',
    showBuildingName: true,
    address: 'Корпус 1, Эт. 42, Кв. 277',
    price: 645423,
    value: 6454200,
    area: 2200,
    size: 'L',
    bedroomCount: 19,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
    isToCompare: true,
    isFavorite: false,
    schemaSrc: apartmentplan,
    schemaSrcFurniture: apartmentplanfurniture,
    schemaSrcSizes: apartmentplansizes,
    measure: 'м²',
    priceForSquare: '777 000',
    currency: '₽',
    cost: '51,45',
    fullCurrency: 'млн. ₽',
    type: 'Апартаменты',
    floor: '42',
    building: 'B',
    number: '277',
    ceillingHigh: 10,
  },
  {
    id: 2,
    buildingName: 'Лаврушинский',
    showBuildingName: true,
    address: 'Корпус 1, Эт. 3, Кв. 5',
    price: 264422000,
    value: 64400220,
    area: 92,
    size: 'M',
    bedroomCount: 3,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
    isToCompare: true,
    isFavorite: false,
    schemaSrc: apartmentplan,
    schemaSrcFurniture: apartmentplanfurniture,
    schemaSrcSizes: apartmentplansizes,
    measure: 'м²',
    priceForSquare: '888 000',
    currency: '₽',
    cost: '59,45',
    fullCurrency: 'млн. ₽',
    type: 'Вилла',
    floor: '3',
    building: 'B',
    number: '5',
    ceillingHigh: 8,
  },
  {
    id: 3,
    buildingName: 'Квартал Jazz',
    showBuildingName: true,
    address: 'Корпус 1, Эт. 37, Кв. 777',
    price: 555020321,
    value: 4344000,
    area: 78,
    size: 'XL',
    bedroomCount: 3,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
    isToCompare: true,
    isFavorite: false,
    schemaSrc: apartmentplan,
    schemaSrcFurniture: apartmentplanfurniture,
    schemaSrcSizes: apartmentplansizes,
    measure: 'м²',
    priceForSquare: '666 000',
    currency: '₽',
    cost: '73,45',
    fullCurrency: 'млн. ₽',
    type: 'Апартаменты',
    floor: '37',
    building: 'A',
    number: '777',
    ceillingHigh: 3,
  },
  {
    id: 4,
    buildingName: 'Обыденский №1',
    showBuildingName: true,
    address: 'Этаж 22, Номер 1 Кв.8',
    price: 54400000,
    value: 540000,
    area: 65,
    size: 'M',
    bedroomCount: 2,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
    isToCompare: true,
    isFavorite: false,
    schemaSrc: apartmentplan,
    schemaSrcFurniture: apartmentplanfurniture,
    schemaSrcSizes: apartmentplansizes,
    measure: 'м²',
    priceForSquare: '999 000',
    currency: '₽',
    cost: '53,45',
    fullCurrency: 'млн. ₽',
    type: 'Апартаменты',
    floor: '22',
    building: 'C',
    number: '8',
    ceillingHigh: 7,
  },
  {
    id: 5,
    buildingName: 'Квартал Jazz',
    showBuildingName: true,
    address: 'Длинный адрес, ул. Пушкина, дом Колотушкина,Кв. 17',
    price: 1233330020,
    value: 644000,
    area: 53,
    size: 'L',
    bedroomCount: 1,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
    isToCompare: true,
    isFavorite: false,
    schemaSrc: apartmentplan,
    schemaSrcFurniture: apartmentplanfurniture,
    schemaSrcSizes: apartmentplansizes,
    measure: 'м²',
    priceForSquare: '555 000',
    currency: '₽',
    cost: '58,45',
    fullCurrency: 'млн. ₽',
    type: 'Вилла',
    floor: '42',
    number: '17',
    ceillingHigh: 5,
  },
  {
    id: 6,
    buildingName: 'Квартал Jazz',
    showBuildingName: true,
    address: 'Корпус 2, Эт. 34, Кв. 73',
    price: 236123123,
    value: 2444000,
    area: 33,
    size: 'S',
    building: 'A',
    bedroomCount: 1,
    currencyCode: CURRENCY_ENUM.RUB,
    imgLink: APARTMENT_IMAGE_LINK,
    isToCompare: true,
    isFavorite: false,
    schemaSrc: apartmentplan,
    schemaSrcFurniture: apartmentplanfurniture,
    schemaSrcSizes: apartmentplansizes,
    measure: 'м²',
    priceForSquare: '10 000 000',
    currency: '₽',
    cost: '23,45',
    fullCurrency: 'млн. ₽',
    type: 'Вилла',
    floor: '2',
    number: '73',
    ceillingHigh: 3,
  },
];

export const DEFAULT_DROPDOWN_SORTING_OPTIONS: ISortOption[] = [
  {
    id: 0,
    title: 'Стоимость сначала дешевле',
    key: 'price_asc',
  },
  {
    id: 1,
    title: 'Стоимость сначала дороже',
    key: 'price_desc',
  },
  {
    id: 2,
    title: 'Площадь сначала меньшая',
    key: 'area_asc',
  },
  {
    id: 3,
    title: 'Площадь сначала большая',
    key: 'area_desc',
  },
  {
    id: 4,
    title: 'Спален сначала меньше',
    key: 'bedrooms_asc',
  },
  {
    id: 5,
    title: 'Спален сначала больше',
    key: 'bedrooms_desc',
  },
  {
    id: 6,
    title: 'Спальни сначала маленькие',
    key: 'bedroom_area_asc',
  },
  {
    id: 7,
    title: 'Спальни сначала большие',
    key: 'bedroom_area_desc',
  },
];
