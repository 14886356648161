import { ContactsContent } from './content/сontacts-сontent';
import { LocationData } from '../model/contacts-data-types';
import styles from './content/contacts-content.module.css';

interface ContactsContentListProps {
  selectedIndex: number;
  setSelectedIndex: (id: number) => void;
  locationData: LocationData[];
  isMobile: boolean;
}

export const ContactsContentList = ({
  selectedIndex,
  locationData,
  setSelectedIndex,
  isMobile,
}: ContactsContentListProps) => {
  const handleBackToMenu = () => {
    setSelectedIndex(-1);
  };

  return (
    <div className={styles.contactsContentContainer} style={{ overflow: selectedIndex === -1 ? 'hidden' : '' }}>
      {locationData.map((data, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: isMobile ? (selectedIndex === index ? 'block' : 'none') : 'block',
            opacity: selectedIndex === index ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
            pointerEvents: selectedIndex === index ? 'auto' : 'none',
          }}
        >
          <ContactsContent data={data} handleBackToMenu={handleBackToMenu} />
        </div>
      ))}
    </div>
  );
};
