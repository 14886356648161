import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ApartmentPlan } from '@/features/apartment-plan';
import { useState } from 'react';
import { Row } from './row';
import { Modal } from '@/shared/ui/modal';
import { IApartment } from '@/pages/roompicker-page/model/types';

interface HousingSelectorBodyProps {
  rows: IApartment[];
  gridTemplateColumns: string;
  compareOnClickHandler?: (apartment: IApartment) => void;
}

export const HousingSelectorBody = ({ rows, gridTemplateColumns, compareOnClickHandler }: HousingSelectorBodyProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApartment, setSelectedApartment] = useState<IApartment | null>(null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedApartment(null);
  };

  return (
    <div {...stylex.props(styles.contentGrid)}>
      {rows.map((row) => (
        <Row
          key={row.id}
          row={row}
          gridTemplateColumns={gridTemplateColumns}
          setIsModalOpen={setIsModalOpen}
          setSelectedApartment={setSelectedApartment}
          compareOnClickHandler={compareOnClickHandler}
        />
      ))}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} isFullScreen isModalForApartmentPlan>
        <ApartmentPlan apartment={selectedApartment} />
      </Modal>
    </div>
  );
};
