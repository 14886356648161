import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  toggleSwitch: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.555rem',
    gap: '0.555rem',
    cursor: 'pointer',
    borderRadius: '0.555rem',
    outline: 'none',
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '@media (max-width: 767px)': {
      gap: '2.222rem',
      padding: '2.222rem',
      borderRadius: '2.222rem',
    },
  },
  toggleSwitchChecked: {
    backgroundColor: colors.brandGhostBackground,
  },
  switchSlider: {
    position: 'relative',
    width: '40px',
    height: '20px',
    backgroundColor: '#b2905b',
    borderRadius: '24px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
  },
  switchInputChecked: {
    backgroundColor: '#AE955F',
  },
  switchInputUnChecked: {
    backgroundColor: '#ccc',
  },

  switchThumb: {
    position: 'absolute',
    width: '16px',
    height: '16px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    top: '2px',
    left: '2px', // Начальное положение бегунка
    transition: 'transform 0.3s ease-in-out',
    transform: 'translateX(0)', // Начальное значение translateX
  },
  switchThumbChecked: {
    transform: 'translateX(20px)', // Сдвиг бегунка вправо при включении
  },
  switchThumbStart: {
    position: 'absolute',
    width: '16px',
    height: '16px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    top: '2px',
    left: '3px',
    transition: 'transform 0.3s ease-in-out',
  },
  switchLabel: {
    fontSize: '0.972rem',
    fontFamily: 'din2014Regular',
    color: '#333',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    '@media (max-width: 767px)': {
      fontSize: '3.888rem',
    },
  },
});
