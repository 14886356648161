import { colors } from '@/shared/tokens/colors.stylex';
import { fonts } from '@/shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  lotCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px',
    gap: '16px',
    borderRadius: '8px',
    border: `1px solid ${colors.tertiaryBorder}`,
    background: colors.primaryInverseForeground,
    fontFamily: fonts.din2014Regular,
    width: '100%',
    minWidth: '324px',
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
});
