import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    width: '100%',
    gap: '1.111rem',
    '@media (max-width: 768px)': {
      gridTemplateColumns: '1fr',
      gap: '1.042rem',
      textAlign: 'center',
    },
  },
  projectInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left',
    gap: '0.556rem',
    paddingLeft: '0.556rem',
  },
  apartmentType: {
    fontSize: '1.667rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '133%',
    color: colors.primaryForeground,
    '@media (max-width: 768px)': {
      fontSize: '3.125rem',
    },
  },
  apartmentDivider: { display: 'flex', alignItems: 'center', justifyContent: 'center' },

  apartmentSizeText: {
    color: colors.primaryForeground,
    fontSize: '1.667rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '133%',
    '@media (max-width: 768px)': {
      fontSize: '3.125rem',
    },
  },
  apartmentSizeTextMeasure: {
    color: colors.tertiaryForeground,
    marginLeft: '0.278rem',
  },

  projectInfoHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.833rem',
    gridColumn: 1 / 2,
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  priceContainer: {
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  projectPrice: {
    color: colors.primaryForeground,
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '133%',
    '@media (max-width: 768px)': {
      fontSize: '2.344rem',
    },
  },
  projectPriceMeasure: {
    marginLeft: '0.278rem',
    color: colors.tertiaryForeground,
  },
  projectName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2.5rem',
    fontSize: '1.111rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    color: colors.primaryForeground,
    gridColumn: 2 / 3,
    '@media (max-width: 768px)': {
      fontSize: '2.083rem',
      textAlign: 'center',
    },
  },
});
