import { Favorites } from '../ui/assets/favorites';
import { HomeBtn } from '../ui/assets/home-btn';
import { MenuBtn } from '../ui/assets/menu-btn';

export const tabMenuItems = [
  {
    title: 'Меню',
    btnIcon: MenuBtn,
  },
  {
    title: 'Выбор недвижимости',
    btnIcon: HomeBtn,
    url: '/selection',
  },
  {
    title: 'Избранное',
    btnIcon: Favorites,
    url: '/favorite',
  },
];
