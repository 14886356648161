import * as stylex from '@stylexjs/stylex';
import { colors } from '@/shared/tokens/colors.stylex.ts';
import { fonts } from '@/shared/tokens/fonts.stylex.ts';

export const styles = stylex.create({
  button: {
    position: 'relative',
    // width: 'fit',
    height: '2.5rem',
    padding: '0.555rem 1.111rem',
    color: {
      default: colors.primaryText,
    },
    backgroundColor: {
      default: colors.primaryBackground,
      ':hover': colors.hoverBackground,
    },
    '@media (max-width: 768px)': {
      color: {
        default: colors.primaryText,
      },
      backgroundColor: {
        default: colors.primaryBackground,
      },
      padding: '2.222rem 4.444rem',
      height: '10rem',
      borderRadius: '2.222rem',
      fontSize: '3.889rem',
    },
    border: `1px solid ${colors.tertiaryBorder}`,
    borderRadius: '0.556rem',
    fontSize: '0.972rem',
    lineHeight: '142%',
    textWrap: 'nowrap',
    fontFamily: fonts.din2014Regular,
    transitionDuration: '500ms',
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
    boxSizing: 'border-box',
  },
  activeBtn: {
    color: colors.primaryInverseText,
    borderColor: { default: colors.primaryInverseBackground },
    backgroundColor: { default: colors.primaryInverseBackground, ':hover': '#292f37' },
    '@media (max-width: 768px)': {
      color: {
        default: colors.primaryInverseText,
      },
      backgroundColor: {
        default: colors.primaryInverseBackground,
      },
    },
  },
  disabledBtn: {
    border: 'none',
    color: colors.ghostText,
    backgroundColor: colors.ghostBackground,
  },
  input: {
    display: 'none',
  },
});
