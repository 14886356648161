import React, { useState } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface ToggleSwitchProps {
  label: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ label, checked = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange && onChange(!isChecked);
  };

  return (
    <label {...stylex.props([styles.toggleSwitch, isChecked && styles.toggleSwitchChecked])} onClick={handleToggle}>
      <div
        {...stylex.props([styles.switchSlider, isChecked ? styles.switchInputChecked : styles.switchInputUnChecked])}
      >
        <div
          {...stylex.props([
            isChecked ? styles.switchThumb : styles.switchThumbStart,
            isChecked && styles.switchThumbChecked,
          ])}
        ></div>
      </div>
      <span {...stylex.props(styles.switchLabel)}>{label}</span>
    </label>
  );
};
