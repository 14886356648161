import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { Dropdown } from '@/shared/ui/components/dropdown';
import { priceData, selectionPropertyTypeOptions } from '../api/mock-api';
import { CheckBoxGroup } from '@/enteties/checkbox-group';
import { useCallback, useEffect, useState } from 'react';
import imgLink from '@/shared/ui/components/empty-search-results/assets/imgNotFound.png';
import { getSortLogic } from '@/features/roompicker-filter-panel/lib/sort';
import {
  MOCK_FILTERS_ADVANTAGES,
  MOCK_FILTERS_BEDROOMS_AREA,
  MOCK_FILTERS_BEDROOMS_COUNT,
  MOCK_FILTERS_ENTRANCE,
  MOCK_FILTERS_HOUSING,
  MOCK_FILTERS_INNER_YARD,
  MOCK_FILTERS_KITCHEN_TYPE,
  MOCK_FILTERS_LEVEL_COUNT,
  MOCK_FILTERS_MANSION_TYPE,
  MOCK_FILTERS_ORIENTATION,
  MOCK_FILTERS_PLAN,
  MOCK_FILTERS_PROJECTS,
  MOCK_FILTERS_TYPE,
} from '@/features/roompicker-filter-panel/shared/mock-filters';
import { Input } from '@/shared/ui/components/input';
import { Glass } from '@/shared/ui/icons/glass';
import { CurrencySwitcher } from '@/features/currency-switcher/ui';
import { ToggleSwitch } from '@/shared/ui/components/toggle-switcher/ui';
import { InputRange } from '@/features/input-range';
import { RoompickerPage } from '@/pages/roompicker-page';
import { useOutletContext } from 'react-router-dom';
import { SelectionContextType } from '../model/types';
import { FilterActions } from '@/enteties/filter-actions';
import { EmptySearchResults } from '@/shared/ui/components/empty-search-results';

const INDEX_OF_ABSENT_ELEMENT = -1;
const TISHINSKIY_INFO = MOCK_FILTERS_PROJECTS.filters[5];
const ILYINKA_INFO = MOCK_FILTERS_PROJECTS.filters[3];
export const Selection = () => {
  const [sortLogic, setSortLogic] = useState<number[]>([]);
  const [inputApartmentNumber, setInputApartmentNumber] = useState('');
  const {
    isFilterVisible,
    toggleFilterVisibility,
    selectedFilters,
    setSelectedFilters,
    selectedIds,
    setSelectedIds,
    resetFilters,
    setTempSelectedFilters,
    matchingApartsCount,
    onShowResult,
    showFilterActions,
    setShowFilterActions,
  } = useOutletContext<SelectionContextType>();

  useEffect(() => {
    const currentSortLogic = getSortLogic(selectedIds);
    setSortLogic(currentSortLogic);
  }, [selectedIds]);

  const onReset = () => {
    setTempSelectedFilters({});
    resetFilters();
  };
  const onProjectClickHandler = useCallback((id: number | null) => {
    setSelectedIds((prev) => {
      if (!id) return prev;
      const indexOfId = prev.indexOf(id);
      return indexOfId === INDEX_OF_ABSENT_ELEMENT
        ? [...prev, id]
        : [...prev.slice(0, indexOfId), ...prev.slice(indexOfId + 1)];
    });
  }, []);

  const handleSearch = () => {
    setSelectedFilters((prev) => ({
      ...prev,
      apartmentNumber: inputApartmentNumber ? [inputApartmentNumber.trim()] : [], // Добавляем фильтр по номеру квартиры
    }));
  };

  const handleFilterChange = (category: string) => (filterValue: string) => {
    setTempSelectedFilters((prev) => ({
      ...prev,
      [category]: prev[category]?.includes(filterValue)
        ? prev[category].filter((value) => value !== filterValue)
        : [...(prev[category] || []), filterValue],
    }));
  };

  const handleRangeChange = (category: string, min: number, max: number) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: [String(min), String(max)], // Преобразуем числа в строки для унификации
    }));
  };

  return (
    <>
      <div {...stylex.props(styles.selectionContainer)}>
        <div {...stylex.props(styles.filterWrap(isFilterVisible))}>
          {showFilterActions && (
            <div {...stylex.props(styles.desktopOnly)}>
              <FilterActions onShowResult={onShowResult} onReset={onReset} apartsToCount={matchingApartsCount} />
            </div>
          )}
          <div {...stylex.props(styles.dopdownWrap)}>
            <Dropdown
              options={selectionPropertyTypeOptions}
              isSelectionPropertyTypeKey={true}
              label="Тип недвижимости"
            />
          </div>
          <div {...stylex.props(styles.checkBoxWrapper)}>
            <Input
              rightIcon={<Glass />}
              placeholder="Введите номер квартиры"
              label="Номер лота"
              onChange={(e) => setInputApartmentNumber(e.target.value)}
              onClick={handleSearch}
            />
            <CurrencySwitcher />
            <CheckBoxGroup
              title={MOCK_FILTERS_PROJECTS.title}
              filters={MOCK_FILTERS_PROJECTS.filters}
              onCheckboxClick={onProjectClickHandler}
              sortLogic={sortLogic}
              onFilterChange={handleFilterChange('projects')}
              selectedIds={selectedIds}
            />
            <div {...stylex.props(styles.toggleSwitcherWrap)}>
              <ToggleSwitch label="Дом готов" />
              <ToggleSwitch label="Скрыть забронированные" />
            </div>

            <CheckBoxGroup
              title={MOCK_FILTERS_BEDROOMS_COUNT.title}
              filters={MOCK_FILTERS_BEDROOMS_COUNT.filters}
              onFilterChange={handleFilterChange('bedrooms')}
              selectedIds={selectedIds}
            />
            <CheckBoxGroup
              title={MOCK_FILTERS_BEDROOMS_AREA.title}
              filters={MOCK_FILTERS_BEDROOMS_AREA.filters}
              onFilterChange={handleFilterChange('area')}
              selectedIds={selectedIds}
            />
            <CheckBoxGroup
              title={MOCK_FILTERS_TYPE.title}
              filters={MOCK_FILTERS_TYPE.filters}
              onFilterChange={handleFilterChange('type')}
              selectedIds={selectedIds}
            />
            <CheckBoxGroup
              title={MOCK_FILTERS_LEVEL_COUNT.title}
              filters={MOCK_FILTERS_LEVEL_COUNT.filters}
              onFilterChange={handleFilterChange('levelCount')}
              selectedIds={selectedIds}
            />
            <CheckBoxGroup
              title={MOCK_FILTERS_HOUSING.title}
              filters={MOCK_FILTERS_HOUSING.filters}
              onFilterChange={handleFilterChange('housing')}
              selectedIds={selectedIds}
            />
            <CheckBoxGroup
              title={MOCK_FILTERS_ENTRANCE.title}
              filters={MOCK_FILTERS_ENTRANCE.filters}
              onFilterChange={handleFilterChange('entrance')}
              selectedIds={selectedIds}
            />
            {selectedIds.includes(TISHINSKIY_INFO.id) && (
              <CheckBoxGroup
                title={MOCK_FILTERS_INNER_YARD.title}
                filters={MOCK_FILTERS_INNER_YARD.filters}
                onFilterChange={handleFilterChange('innerYard')}
                selectedIds={selectedIds}
              />
            )}
            {selectedIds.includes(ILYINKA_INFO.id) && (
              <CheckBoxGroup
                title={MOCK_FILTERS_MANSION_TYPE.title}
                filters={MOCK_FILTERS_MANSION_TYPE.filters}
                onFilterChange={handleFilterChange('mansionType')}
                selectedIds={selectedIds}
              />
            )}
            {priceData.map((item) => (
              <InputRange
                key={item.category}
                title={item.title}
                priceData={item.data}
                onRangeChange={(min, max) => handleRangeChange(item.category, min, max)}
              />
            ))}

            <CheckBoxGroup
              title={MOCK_FILTERS_ADVANTAGES.title}
              filters={MOCK_FILTERS_ADVANTAGES.filters}
              onFilterChange={handleFilterChange('advantages')}
              selectedIds={selectedIds}
            />
            <CheckBoxGroup
              title={MOCK_FILTERS_KITCHEN_TYPE.title}
              filters={MOCK_FILTERS_KITCHEN_TYPE.filters}
              onFilterChange={handleFilterChange('kitchenType')}
              selectedIds={selectedIds}
            />
            <CheckBoxGroup
              title={MOCK_FILTERS_PLAN.title}
              filters={MOCK_FILTERS_PLAN.filters}
              onFilterChange={handleFilterChange('plan')}
              selectedIds={selectedIds}
            />
            <CheckBoxGroup
              title={MOCK_FILTERS_ORIENTATION.title}
              filters={MOCK_FILTERS_ORIENTATION.filters}
              onFilterChange={handleFilterChange('orientation')}
              selectedIds={selectedIds}
            />
          </div>
        </div>
        {Object.keys(selectedFilters).length > 0 && matchingApartsCount === 0 ? (
          <EmptySearchResults
            imgLink={imgLink}
            notFoundTxt="Подходящие лоты не найдены"
            changeFilterTxt="Пожалуйста, измените критерии поиска"
          />
        ) : (
          <RoompickerPage
            toggleFilterVisibility={toggleFilterVisibility}
            isFilterVisible={isFilterVisible}
            selectedFilters={selectedFilters}
          />
        )}
      </div>
    </>
  );
};
