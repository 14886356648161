import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { TabSwitch } from '../tabswitch';
import { useState } from 'react';
import { ModalHeader } from './header';
import { ModalBody } from './body';
import { IApartment } from '@/pages/roompicker-page/model/types';

const tabs = ['C мебелью', 'Без размеров', 'С размерами'];

export interface ApartmentPlanProps {
  apartment: IApartment | null;
}

export const ApartmentPlan = ({ apartment }: ApartmentPlanProps) => {
  const [active, setActive] = useState('C мебелью');
  if (!apartment) return null;
  return (
    <div {...stylex.props(styles.container)}>
      <ModalHeader apartment={apartment} />
      <ModalBody apartment={apartment} active={active} />
      <div {...stylex.props(styles.footer)}>
        <TabSwitch tabs={tabs} active={active} setActive={setActive} />
      </div>
    </div>
  );
};
