import { EmptySearchResultsProps } from '../model/empty-search-results-type';
import styles from './empty-search-results.module.css';

export const EmptySearchResults = ({ imgLink, notFoundTxt, changeFilterTxt }: EmptySearchResultsProps) => {
  return (
      <div className={styles.resultsWrapper}>
        <img src={imgLink} alt="" className={styles.img} />
        <h3 className={styles.notFoundTxt}>{notFoundTxt}</h3>
        <p className={styles.changeFilterTxt}>{changeFilterTxt}</p>
    </div>
  );
};
