import { colors } from '@/shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  bodyWrapper: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  body: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      padding: '1.562rem',
      maxHeight: '90%',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
});
