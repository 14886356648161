import { SelectionHeader } from '@/features/header/ui/selection-header/ui';
import { IApartment } from '@/pages/roompicker-page/model/types';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

export const SelectionLayout = () => {
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: string[];
  }>({});
  const [apartsToShow, setApartsToShow] = useState<IApartment[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [matchingApartsCount, setMatchingApartsCount] = useState(0);
  const [showFilterActions, setShowFilterActions] = useState(false);
  const [tempSelectedFilters, setTempSelectedFilters] = useState(selectedFilters); // Временное состояние для фильтров

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prev) => !prev);
  };
  console.log({ tempSelectedFilters });

  useEffect(() => {
    // Подсчёт подходящих лотов на основе временных фильтров
    const countMatchingAparts = () => {
      // Проверка на пустые фильтры
      const hasActiveFilters = Object.values(tempSelectedFilters).some((values) => values.length > 0);

      if (!hasActiveFilters) {
        setMatchingApartsCount(0); // Если фильтры пусты, показываем 0 доступных лотов
        return;
      }

      // Фильтрация лотов на основе выбранных фильтров
      const matchingAparts = apartsToShow.filter((apartment) => {
        return Object.entries(tempSelectedFilters).every(([key, values]) => {
          if (!values.length) return true;
          switch (key) {
            case 'projects':
              return values.includes(apartment.buildingName!);
            case 'bedrooms':
              return values.includes(String(apartment.bedroomCount));
            case 'area':
              return values.includes(apartment.size);
            case 'type':
              return values.includes(String(apartment.type));
            case 'housing':
              return values.includes(apartment.building || '');
            case 'apartmentNumber':
              return values.some((number) => String(apartment.number).toLowerCase() === number.toLowerCase());
            case 'floorRange': {
              const [minFloor, maxFloor] = values.map(Number);
              return Number(apartment.floor) >= minFloor && Number(apartment.floor) <= maxFloor;
            }
            case 'areaRange': {
              const [minArea, maxArea] = values.map(Number);
              return Number(apartment.area) >= minArea && Number(apartment.area) <= maxArea;
            }
            case 'priceRange': {
              const [minPrice, maxPrice] = values.map(Number);
              const cost =
                typeof apartment.cost === 'string'
                  ? parseFloat(apartment.cost.replace(',', '.'))
                  : Number(apartment.cost);
              return cost >= minPrice && cost <= maxPrice;
            }
            case 'pricePerSquareRange': {
              const [minPricePerSq, maxPricePerSq] = values.map(Number);
              const priceForSquare = apartment.value;
              return priceForSquare >= minPricePerSq && priceForSquare <= maxPricePerSq;
            }
            case 'ceilingHeightRange': {
              const [minCeilingHeight, maxCeilingHeight] = values.map(Number);
              return apartment.ceillingHigh! >= minCeilingHeight && apartment.ceillingHigh! <= maxCeilingHeight;
            }
            default:
              return true;
          }
        });
      });
      console.log({ matchingAparts });

      setMatchingApartsCount(matchingAparts.length);
      // Устанавливаем количество подходящих лотов
    };

    countMatchingAparts(); // Вызываем функцию для подсчета при изменении фильтров
  }, [tempSelectedFilters, apartsToShow]);

  useEffect(() => {
    if (matchingApartsCount > 0 && !showFilterActions) {
      setShowFilterActions(true);
    }
  }, [matchingApartsCount, showFilterActions]);

  // функция для сброса фильтров
  const resetFilters = () => {
    setSelectedFilters({});
    setSelectedIds([]);
    setShowFilterActions(false);
  };

  const onShowResult = () => {
    setSelectedFilters(tempSelectedFilters);
  };
  return (
    <>
      <SelectionHeader
        isFilterVisible={isFilterVisible}
        toggleFilterVisibility={toggleFilterVisibility}
        apartsToShow={apartsToShow}
        resetFilters={resetFilters}
      />
      <Outlet
        context={{
          selectedFilters,
          setSelectedFilters,
          isFilterVisible,
          toggleFilterVisibility,
          apartsToShow,
          setApartsToShow,
          selectedIds,
          setSelectedIds,
          resetFilters,
          tempSelectedFilters,
          setTempSelectedFilters,
          matchingApartsCount,
          setMatchingApartsCount,
          onShowResult,
          showFilterActions,
          setShowFilterActions,
        }}
      />
    </>
  );
};
